interface IMonth {
   number: number;
   name: string;
}

export const Months: IMonth[] = [
   {
      number: 0,
      name: "Январь",
   },
   {
      number: 1,
      name: "Февраль",
   },
   {
      number: 2,
      name: "Март",
   },
   {
      number: 3,
      name: "Апрель",
   },
   {
      number: 4,
      name: "Май",
   },
   {
      number: 5,
      name: "Июнь",
   },
   {
      number: 6,
      name: "Июль",
   },
   {
      number: 7,
      name: "Август",
   },
   {
      number: 8,
      name: "Сентябрь",
   },
   {
      number: 9,
      name: "Октябрь",
   },
   {
      number: 10,
      name: "Ноябрь",
   },
   {
      number: 11,
      name: "Декабрь",
   }
];

export const showMonthByNumber = (num: number): string => {
   const monthName: IMonth | undefined = Months.find((object) => object.number === num);

   if(monthName) {
      return monthName.name;
   } else {
      throw new Error("Неверный номер месяца");
   }
}