import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
// import {ILineChartData} from "../../types/chart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface ILineChart {
    data: any;
    chartWidth: number;
    chartHeight: number;
    isLegend: boolean;
}

const LineChart: React.FC<ILineChart> = ({ data, chartHeight, chartWidth, isLegend }) => {
    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: isLegend,
                position: "bottom",
                align: "start",
                padding: 100,
                labels: {
                    boxWidth: 25,
                    boxHeight: 5,
                }
            }
        }
    };

    return (
        <Line
            data={data}
            options={options}
            width={chartWidth}
            height={chartHeight}
        />
    );
};

export default LineChart;