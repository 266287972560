import React, { useState, useEffect, useRef, useContext } from 'react';
import Sidebar from "./Sidebar";
import CalendarWeek from "./CalendarWeek";
import Modal from "../UI/Modal";
import EventForm from "./EventForm";
import { IEvent } from "../../types/events";
import Title from '../Title';
import { DateContext } from '../../contexts/DateContext';

import { localTimezone } from '../UI/DatePicker';

const CalendarPage = () => {
    Title("Медиапланирование ВКО");
    const [calendarPageHeight, setCalendarPageHeight] = useState<number>(0);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const {selectedDate, setSelectedDate} = useContext(DateContext);
    const [currentEvent, setCurrentEvent] = useState<IEvent | null>(null);
    const [selectedMedia, setSelectedMedia] = useState<number[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<number | null>(null);
    const calendarWeekRef = useRef<any>(null);

    const calculatePageSize = () => {
        const pageHeight = window.innerHeight;
        const header = document.getElementById("header");
        if (header) {
            const headerHeight = header.offsetHeight;
            setCalendarPageHeight(pageHeight - headerHeight);
        }
    };

    const handleUpdateFilters = (updateSelectedMedia: number[], updateSelectedOrganization: number | null) => {
        setSelectedMedia(updateSelectedMedia);
        setSelectedOrganization(updateSelectedOrganization);
    }

    const onSelectDate = (selectedDate: Date) => {
        setSelectedDate(selectedDate);
        sessionStorage.setItem("selectedDay", localTimezone(selectedDate));
    }

    useEffect(() => {
        if (visualViewport) {
            visualViewport.addEventListener('resize', () => {
                calculatePageSize();
            });
        }
        calculatePageSize();
    }, []);

    const openEditModal = ( event: IEvent | null = null) => {
        setCurrentEvent(event);
        setIsOpenModal(true);
    };

    const closeModal = () => {
        setCurrentEvent(null);
        setIsOpenModal(false);
    };

    return (
        <>
            <div className="calendar-page" style={{height: calendarPageHeight}}>
                <Sidebar
                    openModal={() => setIsOpenModal(true)}
                    onSelect={onSelectDate}
                    selectedOrganization={selectedOrganization}
                    selectedMedia={selectedMedia}
                    onUpdateFilters={handleUpdateFilters}
                />
                <CalendarWeek
                    ref={calendarWeekRef}
                    selectedDate={selectedDate}
                    selectedOrganization={selectedOrganization}
                    selectedMedia={selectedMedia}
                    openEditModal={openEditModal}
                />
            </div>
            
            <Modal isOpen={isOpenModal}>
                <EventForm
                    closeModal={closeModal}
                    event={currentEvent}
                    onUpdate={() => calendarWeekRef.current?.loadEventData()}
                    selectedDayInCalendar={currentEvent === null ? selectedDate : new Date(currentEvent.date)}
                />
            </Modal>
        </>
    );
};

export default CalendarPage;
