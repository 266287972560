import React, { useEffect, useRef, useState } from "react";
import { ArrowDown, BurgerMenu } from "../Icons";
import { IOption } from "../../types";

interface IDropdown {
  options: IOption[];
  onSelect: (option: IOption) => void;
  selectedOpt?: IOption | null;
  burgerMenuIcon?: boolean;
  inputSearch?: boolean;
  label?: string;
  defaultText?: string;
  error?: boolean;
  errorText?: string;
  withoutBorder?: boolean;
  disabled?: boolean;
}

const Dropdown: React.FC<IDropdown> = ({
  options,
  onSelect,
  selectedOpt,
  burgerMenuIcon,
  inputSearch,
  label,
  defaultText,
  error,
  errorText,
  withoutBorder,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(
    selectedOpt ? selectedOpt : null
  );
  const [searchText, setSearchText] = useState<string>("");
  const dropdownRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  }

  const handleOptionClick = (option: IOption) => {
    setSelectedOption(option);
    setSearchText(option.label);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(selectedOpt || null);
    setSearchText(selectedOpt ? selectedOpt.label : "");
  }, [selectedOpt]);

  useEffect(() => {
    if (isOpen && inputSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen, inputSearch]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="dropdown-wrapper">
      {label && <div className="dropdown-label">{label}</div>}
      <div
        className={`dropdown
                    ${burgerMenuIcon ? "burger" : ""}
                    ${
                      withoutBorder
                        ? `dropdown-without-border ${
                            selectedOption ? selectedOption.value : ""
                          }`
                        : ""
                    }
                `}
        ref={dropdownRef}
        onClick={handleToggle}
      >
        <div
          className={`dropdown-btn ${
            burgerMenuIcon
              ? `${isOpen ? "burger-btn active-burger" : "burger-btn"}`
              : ""
          } ${error ? "dropdown-error" : ""}`}
        >
          {burgerMenuIcon ? (
            <BurgerMenu />
          ) : inputSearch && isOpen ? (
            <input
              type="text"
              className="dropdown-search"
              placeholder={defaultText}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              ref={inputRef}
            />
          ) : (
            <>
              {selectedOption ? selectedOption.label : defaultText}
              <ArrowDown />
            </>
          )}
        </div>

        {isOpen && (
          <ul
            className={`dropdown-menu ${burgerMenuIcon ? "burger-menu" : ""}`}
          >
            {(inputSearch ? filteredOptions : options).map((option) => (
              <li
                key={option.value}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && errorText && (
        <div className="dropdown-error-text">{errorText}</div>
      )}
    </div>
  );
};

export default Dropdown;
