import React, { useEffect, useState, useRef } from 'react';
import Button from "../UI/Button";
import {AddButton, AkimMention, ArrowDown, Source} from "../Icons";
import {IAnalyticsPublication, IDistricts, IMediaType, IOption} from "../../types";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import CheckboxList from '../UI/CheckboxList';
import SelectedTags from '../UI/SelectedTags';
import { getSocialMediaIcon } from '../MediaIcons';
import { Positive, Negative, Neutral } from '../Icons';
import DatePicker from '../UI/DatePicker';
import dayjs from 'dayjs';
import {isAdmin, isEditor, isViewer} from '../../data/role';
import Comments from './Comments';

interface IAnalyticsTable {
    publications: IAnalyticsPublication[];
    districts: IDistricts[];
    media: IMediaType[];
    selectedDistricts: IOption[];
    selectedMedia: IOption[];
    onSelectDistricts: (selected: IOption[]) => void;
    onSelectMedia: (selected: IOption[]) => void;
    onButtonClick: (publication: IAnalyticsPublication, coords: DOMRect) => void;
}

const AnalyticsTable: React.FC<IAnalyticsTable> = ({publications, districts, media, selectedDistricts, selectedMedia, onSelectDistricts, onSelectMedia, onButtonClick}) => {
    const [isDistrictCheckboxOpen, setIsDistrictCheckboxOpen] = useState<boolean>(false);
    const [isMediaCheckboxOpen, setIsMediaCheckboxOpen] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedPublication, setSelectedPublication] = useState<{publication: IAnalyticsPublication, emotionalTone: string}>();


    useEffect(() => {
        handleResize();
        if (visualViewport) {
            visualViewport.addEventListener('resize', () => {
                handleResize();
            });
        }
        return () => {
            if (visualViewport) {
                visualViewport.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const handleResize = () => {
        setIsTablet(window.innerWidth <= 1050);
    };

    const toogleDistrictCheckbox = () => {
        setIsDistrictCheckboxOpen(!isDistrictCheckboxOpen);
    }

    const toogleMediaCheckbox = () => {
        setIsMediaCheckboxOpen(!isMediaCheckboxOpen);
    }

    const handleClickAddButton = (e: React.MouseEvent, publication : IAnalyticsPublication) => {
        if ( isEditor() && publication.event_date === null) {
            return;
        }
        const button = e.currentTarget;
        const rect = button.getBoundingClientRect();
        onButtonClick(publication, rect);
    }

    return (
        <>
        <table className="table analytics">
            <thead className="table-header">
                <tr>
                    <th className='cell'>№</th>
                    <th className='cell'>ДАТА</th>
                    <th className='cell'>ТЕМА</th>
                    <th className='cell with-filter'>
                        РАЙОН
                        <div className="arrow-container" onClick={toogleDistrictCheckbox}>
                            <ArrowDown width={15} height={15}/>
                        </div>
                        {isDistrictCheckboxOpen &&
                        <div className="district-checkbox">
                            <CheckboxList
                                options={districts.map(el => ({value: el.id, label: el.name}))}
                                selectedOptions={selectedDistricts}
                                onChange={onSelectDistricts}
                                onCancel={toogleDistrictCheckbox}
                            />
                        </div>
                        }
                    </th>
                    <th className='cell' colSpan={3}>КОММЕНТАРИИ</th>
                    <th className='cell with-filter'>
                        ИСТОЧНИК
                        <div className="arrow-container" onClick={toogleMediaCheckbox}>
                            <ArrowDown width={15} height={15}/>
                        </div>
                        {isMediaCheckboxOpen &&
                        <div className="media-checkbox">
                            <CheckboxList
                                options={media.map(el => ({value: el.media_type, label: el.verbose_name}))}
                                selectedOptions={selectedMedia}
                                onChange={onSelectMedia}
                                onCancel={toogleMediaCheckbox}
                            />
                        </div>
                        }
                    </th>
                    {(isAdmin() || isEditor()) &&
                    <th className='cell'>ДЕЙСТВИЕ</th>
                    }
                </tr>
                <tr>
                    <th className='cell'></th>
                    <th className='cell'></th>
                    <th className='cell'></th>
                    <th className='cell' style={{width: "280px", minWidth: isTablet ? "120px" : "160px"}}>
                        <div className='districts'>
                            <SelectedTags
                                selected={selectedDistricts}
                                sliceCount={isTablet ? 2 : 3}
                                onClickMore={() => setIsDistrictCheckboxOpen(true)}
                                onChange={onSelectDistricts}
                            />
                        </div>
                    </th>
                    <th className='cell status'><Positive/></th>
                    <th className='cell status'><Neutral/></th>
                    <th className='cell status'><Negative/></th>
                    <th className='cell' style={{width: "280px", minWidth: isTablet ? "120px" : "160px"}}>
                        <div className='media'>
                            <SelectedTags
                                selected={selectedMedia}
                                sliceCount={isTablet ? 2 : 5}
                                onClickMore={() => setIsMediaCheckboxOpen(true)}
                                onChange={onSelectMedia}
                            />
                        </div>
                    </th>
                    <th className='cell'></th>
                </tr>
            </thead>

            <tbody className='table-body'>
            {publications.map((publication, index) =>
                <tr className={index % 2 ? "dark-row" : ""} key={publication.id}>
                    <td className='cell'>{index + 1}</td>
                    <td className='cell'>{format(publication.posted_at, "dd.MM.yyyy")}</td>
                    <td className='cell topic' data-tooltip-id={`topic-tooltip-${index}`}>
                        {publication.topic}
                        <Tooltip
                            id={`topic-tooltip-${index}`}
                            place="top"
                            content={publication.topic}
                            style={{ maxWidth: "350px", textWrap: "wrap"}}
                        />
                    </td>
                    <td className='cell district' data-tooltip-id={`topic-tooltip-district-${index}`}>
                        {publication.district_name}
                        <Tooltip
                            id={`topic-tooltip-district-${index}`}
                            place="top"
                            content={publication.district_name}
                            style={{ maxWidth: "350px", textWrap: "wrap"}}
                        />
                    </td>
                    <td className='cell positive cursor' onClick={() => {setIsOpen(true); setSelectedPublication({publication: publication, emotionalTone: "positive"})}}>{publication.count_positive}</td>
                    <td className='cell neutral cursor' onClick={() => {setIsOpen(true); setSelectedPublication({publication: publication, emotionalTone: "neutral"})}}>{publication.count_neutral}</td>
                    <td className='cell negative cursor' onClick={() => {setIsOpen(true); setSelectedPublication({publication: publication, emotionalTone: "negative"})}}>{publication.count_negative}</td>
                    <td className='cell'>
                        <div className='source'>
                            <Link to={publication.url} target="_blank" rel="noopener noreferrer">
                                <div className='media-name'>
                                    {getSocialMediaIcon(publication.media_type)}
                                    {!isTablet && publication.media_name}
                                </div>
                            </Link>
                            <div className='link'>
                                <Link to={publication.url} target="_blank" rel="noopener noreferrer">
                                    <Source/>
                                </Link>
                                {publication.akim_mentioned ? <AkimMention /> : <div />}
                            </div>
                        </div>
                    </td>
                    {(isAdmin() || isEditor() || isViewer()) &&
                    <td className='cell button-cell'>
                        {isTablet?
                        <div
                            className='add-button-container'
                            onClick={(e) => handleClickAddButton(e, publication)}
                        >
                            {publication.event_date === null
                            ? (isAdmin() && <AddButton/>)
                            :
                            <div className="event-date">
                                <span className="event-date-number">
                                    {(new Date(publication.event_date)).getDate()}
                                </span>
                                <span className="event-date-month">
                                    {(new Date(publication.event_date)).toLocaleString("ru", { month: 'long' }).slice(0,3)}
                                </span>
                            </div>
                            }
                        </div>
                        :
                        ( !(publication.event_date === null && isEditor()) &&
                        <Button
                            text={publication.event_date === null? "Добавить в календарь" : `Событие на ${dayjs(publication.event_date).format("DD.MM.YY")}`}
                            onClick={(e) => handleClickAddButton(e, publication)}
                            blueColor={publication.event_date === null}
                        />
                        )
                        }
                    </td>
                    }
                </tr>
            )}
            </tbody>
        </table>
        {isOpen &&
            <Comments selected={selectedPublication!} setIsOpen={setIsOpen}/>
        }
        </>
    );
};

export default AnalyticsTable;