import React, {useEffect, useState} from 'react';
import {DayPicker} from "react-day-picker";
import 'react-day-picker/dist/style.css';
import {ru} from "date-fns/locale";
import {getEvents} from "../../services";
import {IEvent} from "../../types/events";
import {format} from "date-fns";
import dayjs from 'dayjs';

interface IDatePicker {
    selectedDate: Date;
    onSelect: (date: Date) => void;
    onClose?: () => void;
}

export const localTimezone = (date: Date): string => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, 19).replace("T", " ");
}

const DatePicker: React.FC<IDatePicker> = ({onSelect, selectedDate, onClose}) => {
    const [daysWithDots, setDaysWithDots] = useState<Date[]>([]);
    const [daysWithOneGreenOneGray, setDaysWithOneGreenOneGray] = useState<Date[]>([]);
    const [daysWithGrayDots, setDaysWithGrayDots] = useState<Date[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<Date>(selectedDate);

    useEffect(() => {
        loadEventData(getStartAndEndDate().start, getStartAndEndDate().end);
    }, []);

    useEffect(() => {
        setSelectedMonth(selectedDate);
    }, [selectedDate]);

    const loadEventData = async (startDate: string, endDate: string) => {
        const response = await getEvents(startDate, endDate, null, []);
        if (!response.isError) {
            sortEventData(response.data);
        } else {
            console.log(response.error);
        }
    };

    const sortEventData = (events: IEvent[]) => {
        const greenDots: Date[] = [];
        const twoDots: Date[] = [];
        const greyDots: Date[] = [];

        events.forEach((event) => {
            let done = 0;
            let notDone = 0;

            event.publications.forEach((pub) => {
                if (pub.status === "not_done") {
                    notDone++;
                } else {
                    done++;
                }
            });

            if (done && notDone) {
                twoDots.push(new Date(event.date));
            } else if (done && !notDone) {
                greenDots.push(new Date(event.date));
            } else if (!done && notDone) {
                greyDots.push(new Date(event.date))
            }
        });

        let greenDotsArr = greenDots.filter(date => !twoDots.some(compDate => compDate.getTime() === date.getTime()));
        let greyDotsArr = greyDots.filter(date => !twoDots.some(compDate => compDate.getTime() === date.getTime()));
        const sameDates = greenDotsArr.filter(date => greyDotsArr.some(compDate => compDate.getTime() === date.getTime()));

        if (sameDates.length) {
            sameDates.forEach((date) => {
                twoDots.push(date);
            });

            greenDotsArr = greenDotsArr.filter(date => !sameDates.some(compDate => compDate.getTime() === date.getTime()));
            greyDotsArr = greyDotsArr.filter(date => !sameDates.some(compDate => compDate.getTime() === date.getTime()));
        }

        setDaysWithDots(greenDotsArr);
        setDaysWithOneGreenOneGray(twoDots);
        setDaysWithGrayDots(greyDotsArr);
    };

    const onSelectDate = (date: Date) => {
        onSelect(date);
        if (onClose) {
            onClose();
        }
    };

    const modifiers = {
        withDot: (date: Date) => {
            return daysWithDots.some(d => d.toDateString() === date.toDateString());
        },
        oneGreenOneGray: (date: Date) => {
            return daysWithOneGreenOneGray.some(d => d.toDateString() === date.toDateString());
        },
        grayDot: (date: Date) => {
            return daysWithGrayDots.some(d => d.toDateString() === date.toDateString());
        },
        today: (date: Date) => date.toDateString() === new Date().toDateString(),
    };

    const modifiersClassNames = {
        withDot: 'with-dot',
        oneGreenOneGray: 'one-green-one-gray',
        grayDot: 'gray-dot',
        today: "today",
    };

    const handleMonthChange = (newMonth: Date) => {
        loadEventData(getStartAndEndDate(newMonth).start, getStartAndEndDate(newMonth).end);
        setSelectedMonth(newMonth);
    };

    const getStartAndEndDate = (date?: Date) => {
        const today =  date ? date : new Date();
        let lastMonth = today.getMonth() - 1;
        let nextMonth = today.getMonth() + 1;
        let nextYear = today.getFullYear();
        let lastYear = today.getFullYear();

        if (lastMonth < 0) {
            lastMonth = 11;
            lastYear--;
        }

        if (nextMonth > 11) {
            nextMonth = 0;
            nextYear++;
        }

        return {
            start: format(new Date(lastYear, lastMonth, 20), "yyyy-MM-dd"),
            end: format(new Date(nextYear, nextMonth, 10), "yyyy-MM-dd")
        }
    };

    return (
        <div className="date-picker">
            <DayPicker
                mode="single"
                selected={selectedDate}
                onDayClick={(date) => onSelectDate(date)}
                locale={ru}
                showOutsideDays
                modifiers={modifiers}
                modifiersClassNames={modifiersClassNames}
                onMonthChange={handleMonthChange}
                month={selectedMonth}
            />
        </div>
    );
};

export default DatePicker;