import React from 'react';
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
// import {ILineChartData} from "../../types/chart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface IBarChart {
    data: any;
    chartWidth: number;
    chartHeight: number;
    isLegend: boolean;
}

const BarChart: React.FC<IBarChart> = ({ data, chartHeight, chartWidth, isLegend }) => {
    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: isLegend,
                position: "bottom",
                align: "start",
                padding: 100,
                labels: {
                    boxWidth: 25,
                    boxHeight: 5,
                }
            }
        },
        scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
    };

    return (
        <Bar
            data={data}
            options={options}
            width={chartWidth}
            height={chartHeight}
        />
    );
};

export default BarChart;