import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar, Plus, Filter } from "../Icons";
import DatePicker from "../UI/DatePicker";
import { DateContext } from "../../contexts/DateContext";
import HeaderSlider from "../HeaderSlider/HeaderSlider";
import { isAdmin, isEditor } from "../../data/role";
import CalendarFilter from "./CalendarFilters";
import Modal from "../UI/Modal";
import { Cancel } from "../Icons";
import Button from "../UI/Button";
import Dropdown from "../UI/Dropdown";
import { monthsOptions, statisticYears } from "../../data/dropdownValues";
import { IOption } from "../../types";
import { getDocxReport, getEventsReport } from "../../services";

interface ISidebar {
    openModal: () => void;
    onSelect: (selectedDate: Date) => void;
    selectedMedia: number[];
    selectedOrganization: number | null;
    onUpdateFilters: (
        selectedtedMedia: number[],
        selectedOrganization: number | null
    ) => void;
}

const Sidebar: React.FC<ISidebar> = ({
    openModal,
    onSelect,
    onUpdateFilters,
    selectedMedia,
    selectedOrganization,
}) => {
    const { selectedDate, calendarPeriod } = useContext(DateContext);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [media, setMedia] = useState<number[]>(selectedMedia);
    const [organization, setOrganization] = useState<number | null>(
        selectedOrganization
    );
    const datePickerContainer = useRef<HTMLDivElement>(null);

    const [isOpenExportModal, setIsOpenExportModal] = useState<boolean>(false);
    const [year, setYear] = useState<IOption>(statisticYears[0]);
    const [month, setMonth] = useState<IOption>(
        monthsOptions[new Date().getMonth()]
    );

    useEffect(() => {
        setMedia(selectedMedia);
    }, [selectedMedia]);

    useEffect(() => {
        setOrganization(organization);
    }, [organization]);

    useEffect(() => {
        handleResize();
        if (visualViewport) {
            visualViewport.addEventListener("resize", () => {
                handleResize();
            });
        }
        return () => {
            if (visualViewport) {
                visualViewport.removeEventListener("resize", handleResize);
            }
        };
    }, []);

    const handleResize = () => {
        setIsTablet(window.innerWidth <= 925);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            datePickerContainer.current &&
            !datePickerContainer.current.contains(event.target as Node)
        ) {
            setIsOpenCalendar(false);
        }
    };

    const updateMediaAndOrganization = (
        updateSelectedMedia: number[],
        updateSelectedOrganization: number | null
    ) => {
        setMedia(updateSelectedMedia);
        setOrganization(updateSelectedOrganization);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const onButtonClick = () => {
        setIsOpenExportModal(true);
    };

    const downloadFile = async () => {
        const token = localStorage.getItem("authData")
            ? JSON.parse(localStorage.getItem("authData") as string).auth_token
            : null;
        if (token) {
            const response = await getEventsReport(
                token,
                Number(year.value),
                Number(month.value)
            );

            if (!response.isError) {
                const fileUrl = window.URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute(
                    "download",
                    `events-report-${year.value}_${month.value}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            } else {
                console.error(
                    "Произошла ошибка при скачивании файла:",
                    response.error
                );
            }
            setIsOpenExportModal(false);
        }
    };

    return (
        <>
            <div className="sidebar">
                <div className="sidebar-header">
                    {(isAdmin() || isEditor()) && (
                        <>
                            <div
                                className={`sidebar-btn ${
                                    isTablet ? "sidebar-btn-tablet" : ""
                                }`}
                                onClick={openModal}
                            >
                                <div className="sidebar-btn-plus">
                                    <Plus />
                                </div>
                                {!isTablet && <p>Создать</p>}
                            </div>
                            <div
                                className={`sidebar-btn ${
                                    isTablet ? "sidebar-btn-tablet" : ""
                                }`}
                                onClick={onButtonClick}
                            >
                                <p>Экспорт</p>
                            </div>
                        </>
                    )}
                    {isTablet && (
                        <>
                            <div className="header-center">
                                <HeaderSlider calendarPeriod={calendarPeriod} />
                            </div>
                            <div className="sidebar-header-actions">
                                <div className="sidebar-header-calendar-container">
                                    <div
                                        className="sidebar-header-calendar-icon"
                                        onClick={() => setIsOpenCalendar(true)}
                                    >
                                        <Calendar width={24} height={24} />
                                    </div>
                                    {isOpenCalendar && (
                                        <div
                                            className="sidebar-header-calendar"
                                            ref={datePickerContainer}
                                        >
                                            <DatePicker
                                                selectedDate={selectedDate}
                                                onSelect={(date) =>
                                                    onSelect(date)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="sidebar-header-filter-icon"
                                    onClick={() => setIsOpenFilters(true)}
                                >
                                    <Filter width={24} height={24} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {!isTablet && (
                    <>
                        <DatePicker
                            selectedDate={selectedDate}
                            onSelect={(date) => onSelect(date)}
                        />
                        <div className="sidebar-body">
                            <CalendarFilter onUpdateFilters={onUpdateFilters} />
                        </div>
                    </>
                )}
                {isOpenFilters && (
                    <Modal isOpen={isOpenFilters} isSmallModal={true}>
                        <div
                            className="delete-modal-close-icon"
                            onClick={() => setIsOpenFilters(false)}
                        >
                            <Cancel color="#7A7A7A" />
                        </div>
                        <div className="calendar-filter-modal">
                            <CalendarFilter
                                onUpdateFilters={updateMediaAndOrganization}
                                media={media}
                                organization={organization}
                            />
                        </div>
                        <div className="delete-modal-content-btns">
                            <Button
                                text="Отмена"
                                onClick={() => setIsOpenFilters(false)}
                            />
                            <Button
                                text="Применить"
                                onClick={() => {
                                    onUpdateFilters(media, organization);
                                    setIsOpenFilters(false);
                                }}
                                blueColor
                            />
                        </div>
                    </Modal>
                )}
            </div>

            <Modal isOpen={isOpenExportModal} isSmallModal>
                <div className="export-modal">
                    <div
                        className="export-modal-close-icon"
                        onClick={() => setIsOpenExportModal(false)}
                    >
                        <Cancel />
                    </div>
                    <div className="export-modal-year">
                        <Dropdown
                            options={statisticYears}
                            selectedOpt={year}
                            onSelect={(option) => setYear(option)}
                            label="Выберите год"
                        />
                    </div>
                    <div className="export-modal-month">
                        <Dropdown
                            options={monthsOptions}
                            selectedOpt={month}
                            onSelect={(option) => setMonth(option)}
                            label="Выберите месяц"
                        />
                    </div>
                    <div className="export-modal-button">
                        <Button
                            text="Экспорт"
                            onClick={downloadFile}
                            blueColor
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Sidebar;
