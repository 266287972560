import { Dispatch, SetStateAction, useState } from "react";
import { deleteUser, editUser } from "../../services";
import { IUser, IUserGroup } from "../../types";
import { Cancel, Delete, Edit } from "../Icons";
import Modal from "../UI/Modal";
import UsersForm from "./UsersForm";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

interface IUsersTable {
   users: IUser[];
   loadUsers: () => void;
}

const UsersTable: React.FC<IUsersTable> = ({users, loadUsers}) => {
    const [editButtonClicked, setEditButtonClicked] = useState<boolean>(false);
    const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const navigate = useNavigate();

    let TOKEN: string;

    try {
        TOKEN = JSON.parse(localStorage.getItem("authData") as string).auth_token;
    } catch (e) {
        localStorage.removeItem("authData");
        navigate("/authorization");
    }
    
    const handleEdit = async(user: IUser): Promise<void> => {
        const response = await editUser(user, TOKEN);
        if (!response.isError) {
            loadUsers();
        }
    }

    const handleDelete = async(id: number): Promise<void> => {
        const response = await deleteUser(id, JSON.parse(localStorage.getItem("authData") as string).auth_token);
        if (!response.isError) {
            loadUsers();
        }
    }

    return (
        <div className="users-table">
            <table className="table">
                <thead className="table-header">
                    <tr>
                        <th className='cell'>№</th>
                        <th className='cell'>ИМЯ</th>
                        <th className='cell'>ПОЧТА</th>
                        <th className='cell'>ДОСТУП</th>
                        <th className='cell' colSpan={2}>ДЕЙСТВИЕ</th>
                    </tr>
                </thead>

                <tbody>
                {users.map((user, index) =>
                    <tr className={index % 2 ? "dark-row" : ""} key={user.id}>
                        <td className='cell'>{index + 1}</td>
                        <td className='cell'>{user.full_name}</td>
                        <td className='cell'>{user.email}</td>
                        <td className='cell users-roles'>
                            {user.group ? user.group.verbose_name : null}
                        </td>
                        <td className="media-action-button">
                            <div className="users-action">
                                <span 
                                    className="users-action-button"
                                    onClick={() => {
                                        setEditButtonClicked(true);
                                        setSelectedUser(user);
                                    }}
                                >
                                    <Edit />
                                </span>
                                    
                                <span 
                                    className="users-action-button"
                                    onClick={() => {
                                        setDeleteButtonClicked(true);
                                        setSelectedUser(user);
                                    }}
                                >
                                    <Delete />
                                </span>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <Modal isSmallModal isOpen={editButtonClicked}>
                <UsersForm 
                    closeModal={() => setEditButtonClicked(false)}
                    onSubmit={() => {}}
                    headLabel="Редактировать пользователя"
                    isEdit
                    user={selectedUser}
                    onSave={(updatedUser) => {
                        handleEdit(updatedUser);
                        setEditButtonClicked(false);
                    }}
                />
            </Modal>

            <Modal isSmallModal isOpen={deleteButtonClicked}>
            <div className="delete-modal">
                    <div className="delete-modal-close-icon" onClick={() => setDeleteButtonClicked(false)}>
                        <Cancel color="#7A7A7A" />
                    </div>
                    <div className="delete-modal-content">
                        <p className="delete-modal-content-title">Вы уверены что хотите <br/> удалить пользователя:</p>
                        <p className="delete-modal-content-subtitle">
                            {selectedUser?.full_name}
                        </p>
                    </div>
                    <div className="delete-modal-content-btns">
                        <Button 
                            text="Удалить" 
                            onClick={() => {
                                setDeleteButtonClicked(false);
                                handleDelete(selectedUser?.id as number);
                            }} 
                            redColor 
                            big 
                        />
                        <Button text="Отмена" onClick={() => setDeleteButtonClicked(false)} big />
                    </div>
                </div> 
            </Modal>
        </div>
    )
}

export default UsersTable;