import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IAnalyticsPublication, IComment } from "../../types";
import Modal from "../UI/Modal";
import { getComments } from "../../services";
import { Cancel } from "../Icons";
import { mentionHintString } from "../../utils/mention-hint-string";

interface ICommentsProps {
   selected: {publication: IAnalyticsPublication, emotionalTone: string};
   setIsOpen: Dispatch<SetStateAction<boolean>>
}

const Comments: React.FC<ICommentsProps> = ({selected, setIsOpen}) => {
   const [comments, setComments] = useState<IComment[]>();

   const loadComments = async() => {
      const result = await getComments(selected.publication.id, selected.emotionalTone);
      if (!result.isError) {
         setComments(result.data);
      }
   }

   useEffect(() => {
      loadComments();
   }, []);

   let adjective: string;
   if (selected.emotionalTone === "positive") {
      adjective = "положительной";
   } else if (selected.emotionalTone === "neutral") {
      adjective = "нейтральной";
   } else {
      adjective = "негативной";
   }

   return (
      <Modal
         isSmallModal
         isOpen={true}
         style={{
               width: "50vw",
               maxWidth: "680px",
               minWidth: "500px",
               maxHeight: "80vh",
               minHeight: "400px",
               overflow: "auto"
         }}>
         <div 
            className="delete-modal-close-icon"
            style={{marginBottom: "24px", position: "sticky", top: 0}}
            onClick={() => setIsOpen(false)}
         >
            <Cancel color="#7A7A7A" />
         </div>

         <div className="analytics-comment-topic">{selected.publication.topic}</div>
         <div className={`analytics-comment-emotion ${selected.emotionalTone}`}>Отзывы {adjective} эмоциональной оценки</div>
         <div className="analytics-comment-wrapper">
         {comments?.map((comment: IComment, index: number) => 
            <div key={index} className="analytics-comment">
               <div>
                  <div style={{display: "flex", gap: "6px"}}>
                     <span className="analytics-comment-number">{index + 1}.</span>
                     <span className="analytics-comment-content" dangerouslySetInnerHTML={{ __html: mentionHintString(comment.text) }} />
                  </div>
                  <div className="analytics-comment-date">{selected.publication.posted_at.substring(8, 10)}.{selected.publication.posted_at.substring(5, 7)}.{selected.publication.posted_at.substring(0, 4)}</div>
               </div>
            </div>
         )}
         {comments?.length === 0
            &&
            <span className="analytics-comment-no">Комментарии отсутствуют.</span>
         }
         </div>
      </Modal>
   )
}

export default Comments;