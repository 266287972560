import { useEffect } from "react";
import Header from "./components/Header";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import { DateContextProvider } from "./contexts/DateContext";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authData = localStorage.getItem("authData");
        if (authData === null || !authData){
            navigate("/authorization");
        }

    }, [location]);

    return (
        <div className="App">
            <DateContextProvider>
                <Header />
                <Outlet />
            </DateContextProvider>
        </div>
    );
};

export default App;
