import React, {useState, useRef, useEffect} from 'react';
import Checkbox from './Checkbox';
import { IOption } from '../../types';
import { Cancel } from '../Icons';
import Button from './Button';

interface ICheckboxList {
    options: IOption[];
    selectedOptions: IOption[];
    onChange: (selected: IOption[]) => void;
    onCancel: () => void;
}

const CheckboxList: React.FC<ICheckboxList> = ({options, selectedOptions, onChange, onCancel}) => {
    const [selectedList, setSelectedList] = useState<IOption[]>(selectedOptions);
    const checkboxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleChange = (status: string, selectOption: IOption) => {
        if (status === "done") {
            setSelectedList([...selectedList, selectOption]);
        }
        else {
            setSelectedList(selectedList.filter((val) => val.value !== selectOption.value));
        }
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (checkboxRef.current && !checkboxRef.current.contains(event.target as Node)) {
            onCancel();
        }
    };

    const handleSelect = () => {
        if (onChange) {
            onChange(selectedList);
            onCancel();
        }
    }

    return (
        <div className="checkbox-list" ref={checkboxRef}>
            {onCancel &&
            <div className="icon-container" onClick={onCancel}>
                <Cancel style={{cursor: "pointer"}}/>
            </div>
            }
            {options.map(el =>
                <Checkbox
                    key={el.value}
                    label={el.label}
                    isChecked={!!selectedList.find(select => select.value === el.value)}
                    onChange={(status) => handleChange(status, el)}
                    labelStyle={{fontSize: "16px", color: "#7A7A7A"}}
                />
            )}
            <div className="button-container">
                <Button
                    text="выбрать"
                    onClick={handleSelect}
                    fontStyle={{fontSize: "16px"}}
                />
            </div>
        </div>
    );
};

export default CheckboxList;