import { IEvent, IEventType, IEventUpdate } from "../types/events";
import {
    IOrganizations,
    ICreateEvent,
    IPublications,
    IAnalyticsPublications,
    IMediaPost,
    IPaginatedData,
    ICategory,
    IDistricts,
    IMediaType,
    ITokenData,
    IUserPermission,
    IUser,
    IParsingEvent,
    IStatuses,
    IParsingEventUpdate,
    IUserGroup,
    IStatistic,
    IMediaStatistic,
    IFilterCategory,
    IComment,
    INotificationPage,
    IWordCloudItem,
} from "../types";

type TResult<DataType> =
    | {
          isError: true;
          error: string;
      }
    | {
          isError: false;
          data: DataType;
      };

type TResultForm<DataType> =
    | {
          isError: true;
          error: any;
      }
    | {
          isError: false;
          data: DataType;
      };

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPublications = async (
    page: number,
    perPage: number,
    search: string
): Promise<TResult<IPaginatedData<IPublications>>> => {
    try {
        const params = new URLSearchParams();
        params.append("page", String(page));
        params.append("per_page", String(perPage));
        params.append("search", String(search));

        const url = `${BASE_URL}/api/publications/media/?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getAllPublications = async (): Promise<
    TResult<IPublications[]>
> => {
    try {
        const url = `${BASE_URL}/api/publications/media/`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const createEvents = async (
    data: ICreateEvent
): Promise<TResultForm<IEvent[]>> => {
    try {
        const url = `${BASE_URL}/api/events/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const parsedData = await response.json();
        if (!response.ok) {
            return { isError: true, error: parsedData };
        }
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getOrganizations = async (): Promise<
    TResult<IOrganizations[]>
> => {
    try {
        const url = `${BASE_URL}/api/organizations/`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getFilterCategory = async (): Promise<
    TResult<IFilterCategory[]>
> => {
    try {
        const url = `${BASE_URL}/api/publications/media/category/`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getEvents = async (
    startDate: string,
    endDate: string,
    organization_id: number | null,
    mediaIds: number[]
): Promise<TResult<IEvent[]>> => {
    try {
        const params = new URLSearchParams();
        params.append("start_date", String(startDate));
        params.append("end_date", String(endDate));
        if (organization_id) {
            params.append("organization_id", String(organization_id));
        }
        if (mediaIds.length > 0) {
            params.append("media_ids", mediaIds.join(","));
        }
        const url = `${BASE_URL}/api/events/?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getEventTypes = async (): Promise<TResult<IEventType[]>> => {
    try {
        const url = `${BASE_URL}/api/events/types/`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const updateEvent = async (
    event: IEventUpdate,
    event_id: number
): Promise<TResult<IEvent[]>> => {
    try {
        const url = `${BASE_URL}/api/events/${event_id}/`;
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(event),
        });
        const parsedData = await response.json();
        if (!response.ok) {
            return { isError: true, error: parsedData };
        }
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const deleteEvent = async (event_id: number): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/events/${event_id}/`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return { isError: false, data: response };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getAnalytics = async (
    page: number,
    perPage: number,
    search: string,
    district_ids: string,
    media_types: string,
    start_date: string,
    end_date: string,
    mention: any
): Promise<TResult<IAnalyticsPublications>> => {
    try {
        const params = new URLSearchParams();
        params.append("page", String(page));
        params.append("per_page", String(perPage));
        params.append("search", String(search));
        params.append("district_ids", String(district_ids));
        params.append("media_types", String(media_types));
        params.append("start_date", String(start_date));
        params.append("end_date", String(end_date));
        params.append("mention", String(mention));

        const url =
            `${BASE_URL}/api/parsing/publications/?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getCategories = async (): Promise<TResult<ICategory[]>> => {
    try {
        const url = `${BASE_URL}/api/publications/media/category/group`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const createMedia = async (
    data: IMediaPost
): Promise<TResultForm<IPublications>> => {
    try {
        const url = `${BASE_URL}/api/publications/media/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getDistricts = async (): Promise<TResult<IDistricts[]>> => {
    try {
        const url = `${BASE_URL}/api/core/districts/`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getMediaType = async (): Promise<TResult<IMediaType[]>> => {
    try {
        const url = `${BASE_URL}/api/parsing/publications/media/type`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const editMedia = async (
    publication: IPublications
): Promise<TResultForm<IPublications>> => {
    try {
        const url = `${BASE_URL}/api/publications/media/${publication.id}/`;
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(publication),
        });
        return response.json();
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const deleteMedia = async (id: number): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/publications/media/${id}/`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return { isError: false, data: response };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const signIn = async (
    email: string,
    password: string
): Promise<TResult<ITokenData>> => {
    try {
        const url = `${BASE_URL}/api/auth/token/login`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
            throw new Error("Failed to get token");
        }

        const parsedData = await response.json();
        return parsedData;
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getPermissions = async (
    token: string
): Promise<TResult<IUserPermission[]>> => {
    try {
        const url = `${BASE_URL}/api/core/permissions/`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getUsers = async (token: string): Promise<TResult<IUser[]>> => {
    try {
        const url = `${BASE_URL}/api/core/users/`;
        const data = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        const parsedData = await data.json();
        if (!data.ok) {
            if (data.status === 401) {
                localStorage.removeItem("authData");
                window.location.pathname = "/authorization";
            }
            return { isError: true, error: parsedData };
        }
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const createUsers = async (
    data: IUser,
    token: string
): Promise<TResultForm<IUser>> => {
    try {
        const url = `${BASE_URL}/api/core/users/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify(data),
        });
        const parsedData = await response.json();
        if (!response.ok) {
            return { isError: true, error: parsedData };
        }
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const editUser = async (
    user: IUser,
    token: string
): Promise<TResultForm<IUser>> => {
    try {
        const url = `${BASE_URL}/api/core/users/${user.id}/`;
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify(user),
        });
        return response.json();
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const deleteUser = async (
    user_id: number,
    token: string
): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/core/users/${user_id}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
        return { isError: false, data: response };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const createParsingEvent = async (
    data: IParsingEvent
): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/parsing/events/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return { isError: false, data: response };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const updateParsingEvent = async (
    event: IParsingEventUpdate,
    event_id: number
): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/parsing/events/${event_id}/`;
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(event),
        });
        const parsedData = await response.json();
        if (!response.ok) {
            return { isError: true, error: parsedData };
        }
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const deleteParsingEvent = async (id: number): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/parsing/events/${id}/`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return { isError: false, data: response };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getParsingEvents = async (
    startDate: string,
    endDate: string
): Promise<TResult<IParsingEvent[]>> => {
    try {
        const params = new URLSearchParams();
        params.append("start_date", String(startDate));
        params.append("end_date", String(endDate));
        const url = `${BASE_URL}/api/parsing/events/?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getStatuses = async (): Promise<TResult<IStatuses[]>> => {
    try {
        const url = `${BASE_URL}/api/parsing/events/statuses`;
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getGroups = async (
    token: string
): Promise<TResult<IUserGroup[]>> => {
    try {
        const url = `${BASE_URL}/api/core/groups/`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const changePassword = async (
    oldPwd: string,
    newPwd: string,
    token: string
): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/core/users/reset-password/`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({
                old_password: oldPwd,
                new_password: newPwd,
            }),
        });
        if (!response.ok) {
            if (response.status === 400) {
                const parsedError = await response.json();
                return { isError: true, error: "error400" };
            }
        }
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getNotifications = async (
    token: string,
    start_date?: string,
    end_date?: string,
    page?: number,
    per_page?: number
): Promise<TResult<INotificationPage | IEvent[]>> => {
    try {
        const params = new URLSearchParams();
        params.append("start_date", String(start_date));
        params.append("end_date", String(end_date));
        params.append("page", String(page));
        params.append("per_page", String(per_page));

        const url =
            `${BASE_URL}/api/events/notifications/?` + params.toString();
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const readNotifications = async (
    token: string,
    ids: number[]
): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/events/notifications/have-been-read`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({ event_ids: ids }),
        });
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getStatisticData = async (
    period: string,
    year: string
): Promise<TResult<IStatistic[] | IStatistic>> => {
    try {
        const params = new URLSearchParams();
        params.append("period", String(period));
        params.append("year", String(year));
        const url = `${BASE_URL}/api/stats/publications?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getStatisticMediaData = async (
    period: string,
    year: string
): Promise<TResult<IMediaStatistic[]>> => {
    try {
        const params = new URLSearchParams();
        params.append("period", String(period));
        params.append("year", String(year));
        const url =
            `${BASE_URL}/api/stats/publications/by-media?` + params.toString();
        const data = await fetch(url, { method: "GET" });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const importFile = async (file: File): Promise<TResult<any>> => {
    try {
        const url = `${BASE_URL}/api/events/import/`;
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        if (!response.ok) {
            return { isError: true, error: response.status.toString() };
        }
        const parsedData = await response.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getComments = async (
    id: number,
    emotionalTone: string
): Promise<TResult<IComment[]>> => {
    try {
        const params = new URLSearchParams();
        params.append("publication_id", String(id));
        params.append("emotional_tone", String(emotionalTone));
        const url =
            `${BASE_URL}/api/parsing/publications/comments/?` +
            params.toString();
        const data = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const parsedData = await data.json();
        return { isError: false, data: parsedData };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getDocxReport = async (
    token: string,
    year: number,
    month: number
): Promise<TResult<any>> => {
    try {
        const params = new URLSearchParams();
        params.append("year", String(year));
        params.append("month", String(month));

        const url = `${BASE_URL}/api/reports/docx/?` + params.toString();
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const blob = await response.blob();
        return { isError: false, data: blob };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getEventsReport = async (
    token: string,
    year: number,
    month: number
): Promise<TResult<any>> => {
    try {
        const params = new URLSearchParams();
        params.append("year", String(year));
        params.append("month", String(month));

        const url = `${BASE_URL}/api/reports/events/xlsx/?` + params.toString();
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const blob = await response.blob();
        return { isError: false, data: blob };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getWordCloud = async (
    start_date: string,
    end_date: string
): Promise<TResult<IWordCloudItem[]>> => {
    try {
        const params = new URLSearchParams();

        if (start_date) {
            params.append("start_date", start_date);
        }
        if (end_date) {
            params.append("end_date", end_date);
        }

        const response = await fetch(
            `${BASE_URL}/api/parsing/word-cloud/?` + params.toString(),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return { isError: false, data: await response.json() };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getOrganizationStatictis = async (
    year?: string
): Promise<TResult<any>> => {
    try {
        const params = new URLSearchParams();

        if (year) {
            params.set("year", year);
        }

        const response = await fetch(
            `${BASE_URL}/api/stats/organizations/publications/?` +
                params.toString(),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return { isError: false, data: await response.json() };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};
