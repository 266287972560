import React, { useMemo } from 'react';

import HeaderAsideMenuStyles from './HeaderAsideMenu.module.scss';

export type AsideMenuItemProps = {
    name: string;
    href: string;
    disabled?: boolean | (() => boolean);
    onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, disabled?: boolean | (() => boolean)): void;
};

const AsideMenuItem: React.FC<AsideMenuItemProps> = ({ name, href, disabled, onClick }) => {
    const disabledComputed = useMemo(() => (typeof disabled === 'function' ? disabled?.() : disabled), [disabled]);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClick) {
            return onClick(event, disabled);
        }

        if (disabledComputed) {
            event.preventDefault();
        }
    };

    return (
        <a
            className={HeaderAsideMenuStyles['header-aside-menu-link']}
            href={href}
            aria-disabled={disabledComputed}
            onClick={handleClick}
        >
            {name}
        </a>
    );
};

export default AsideMenuItem;
