import { useEffect, useState } from "react";
import MediaTable from "./MediaTable";
import Pagination from "../UI/Pagination";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import { getDistricts, getPublications } from "../../services";
import { IDistricts, IPublications } from "../../types";
import MediaForm from "./MediaForm";
import Input from "../UI/Input";
import Title from "../Title";
import {isAdmin, isEditor} from "../../data/role";

const MediaPage = () => {
    Title("СМИ");
    const [publications, setPublications] = useState<IPublications[]>();
    const [districts, setDistricts] = useState<IDistricts[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchParam, setSearchParam] = useState<string>("");
    const perPage = 15;

    useEffect(() => {
        loadPublications(page);
    },[page]);

    useEffect(() => {
        loadDistricts();
    }, [])

    useEffect(() => {
        loadPublications(1);
    }, [searchParam])

    const loadPublications = async (page: number) => {
        const response = await getPublications(page, perPage, searchParam);
        if (!response.isError) {
            const data = response.data;
            setPublications(data.results);
            setTotalCount(data.count);
            setPage(page);
        }
    };

    const loadDistricts = async () => {
        const response = await getDistricts();
        if (!response.isError) {
            const data: IDistricts[] = response.data;
            setDistricts(data);
        }
    };

    return (
        <div className="media">
            <div className="media-header">
                <h2 className="media-title">СМИ</h2>
                  <div className="media-create">
                    {(isAdmin() || isEditor()) &&
                        <Button text="Создать" blueColor onClick={() => setIsOpen(true)}/>
                    }
                </div>
            </div>

            <div className="analytics-filters">
                <div className="analytics-filters-input">
                        <Input
                            value={searchParam}
                            placeholder="Поиск по наименованию"
                            className="search-input"
                            onChange={(e) => setSearchParam(e)}
                        />
                </div>
            </div>

            {publications &&
                <>
                    <MediaTable
                        publications={publications}
                        districts={districts as IDistricts[]}
                        loadPublications={() => loadPublications(page)}
                        currentPage={page}
                        itemsPerPage={perPage}
                    />
                    <Pagination
                        totalCount={totalCount}
                        currentPage={page}
                        perPage={perPage}
                        onChange={(number) => loadPublications(number)}
                    />
                </>
            }

            <Modal isSmallModal isOpen={isOpen}>
                <MediaForm
                    closeModal={() => setIsOpen(false)}
                    onSubmit={() => {
                        loadPublications(1);
                        setIsOpen(false);
                    }}
                    districts={districts}
                />
            </Modal>
        </div>
    );
}

export default MediaPage;