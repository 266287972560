import React, {useEffect, useState} from 'react';
import Input from "../UI/Input";
import Dropdown from "../UI/Dropdown";
import Button from "../UI/Button";
import {createMedia, editMedia, getCategories} from "../../services";
import {ICategory, IDistricts, IMediaPost, IOption, IPublications} from "../../types";

interface IMediaForm {
    closeModal: () => void;
    onSubmit: () => void;
    districts: IDistricts[];
    districtDecoder?:(id:number) => string;
    publicationForEdit?: IPublications;
}

const MediaForm: React.FC<IMediaForm> = ({
    closeModal, 
    onSubmit,
    districts,
    districtDecoder,
    publicationForEdit,
}) => {
    const [mediaName, setMediaName] = useState<string>("");
    const [groupOptions, setGroupOptions] = useState<IOption[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<IOption>({label: "", value: ""});
    const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<IOption>({label: "", value: ""});
    const [districtOptions, setDistrictOptions] = useState<IOption[]>([]);
    const [selectedDistrict, setSelectedDistrict] = useState<IOption>({label: "", value: ""});
    const [isWrong, setIsWrong] = useState<{isName: boolean, isDistrict: boolean, isGroup: boolean, isCategory: boolean}>({isName: false, isDistrict: false, isGroup: false, isCategory: false});
    
    useEffect(() => {
        loadCategories();

        const receivedDistricts = districts.map((object: IDistricts) => ({
        label: object.name, value: object.id
        }));
        setDistrictOptions(receivedDistricts);

        if (publicationForEdit) {
            setSelectedCategory({label: publicationForEdit.category_name as string, value: publicationForEdit.category_name as string});
            setMediaName(publicationForEdit.name);
            setSelectedDistrict(fromStringToOptions(districtDecoder!(publicationForEdit.district_id as number), publicationForEdit.district_id as number));
        }
    }, []);

    useEffect(() => {
        const group = categories.find(cat => cat.group === selectedGroup.value);
        if (group) {
            const newCategoryOptions = group.name.map(name => ({ label: name, value: name }));
            setCategoryOptions(newCategoryOptions);
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (categories.length > 0 && selectedCategory.value) {
          const group = searchGroupByCategory(selectedCategory.value as string);
          setSelectedGroup({ label: group, value: group });
        }
    }, [categories, selectedCategory]);

    const loadCategories = async () => {
        const response = await getCategories();
        if (!response.isError) {
            const data: ICategory[] = response.data;
            setCategories(data);
            const receivedGroups = data.map((object: ICategory) => ({
                label: object.group, value: object.group
            }));
            setGroupOptions(receivedGroups);
        }
    };

    const handleSubmit = async () => {
        const data: IMediaPost = {
            name: mediaName,
            category_name: selectedCategory.label,
            category_group: selectedGroup.label,
            district_id: selectedDistrict.value as number,
        }
    
        let validation = {
            isName: data.name.length === 0,
            isDistrict: !data.district_id,
            isGroup: !data.category_group,
            isCategory: !data.category_name,
        };
    
        const isValid = !Object.values(validation).includes(true);
    
        if (isValid) {
            if (publicationForEdit) {
                const { category_id, ...rest } = publicationForEdit;
                const updatedPublication = {
                    ...rest,
                    ...data,
                };
                const response = await editMedia(updatedPublication);
                if (!response.isError) {
                    onSubmit();
                    closeModal();
                }
            } else {
                const response = await createMedia(data);
                if (!response.isError) {
                    onSubmit();
                    closeModal();
                }
            }
            setIsWrong({ isName: false, isDistrict: false, isGroup: false, isCategory: false });
        } else {
            setIsWrong(validation);
        }
    };

    const fromStringToOptions = (str: string, id?: number): IOption => {
        if (id) {
            return { label: str, value: id };
        }
        return { label: str, value: str };
    };

    const searchGroupByCategory = (category: string): string => {
        const foundCategory = categories.find((object: ICategory) => object.name.includes(category));
        return foundCategory ? foundCategory.group : "error";
    };

    return (
        <div className="media-modal">
            <label className="media-modal-header">{publicationForEdit ? 
                "Редактировать СМИ" 
                : "Создать новое СМИ"}
            </label>
            <Input
                value={mediaName}
                placeholder="Наименование"
                label="Наименование:"
                type="text"
                required
                onChange={(value) => {
                    setMediaName(value);
                    setIsWrong(prevState => ({...prevState, isName:false}));
                }}
                error={isWrong.isName}
                errorText="*Обязательное поле"
            />

            <div className="media-modal-dropdown">
                <label className="media-modal-label">Район:</label>
                <Dropdown
                    options={districtOptions as IOption[]}
                    defaultText="Выберите район"
                    selectedOpt={selectedDistrict.label.length === 0 ? null : selectedDistrict}
                    onSelect={(selected) => {
                        setSelectedDistrict(selected);
                        setIsWrong(prevState => ({...prevState, isDistrict:false}));
                    }}
                    error={isWrong.isDistrict}
                    errorText="*Район не выбран"
                />
            </div>

            <div className="media-modal-dropdown">
                <label className="media-modal-label">Группа:</label>
                <Dropdown
                    options={groupOptions}
                    defaultText="Выберите группу"
                    selectedOpt={selectedGroup.label.length === 0 ? null : selectedGroup}
                    onSelect={(selected) => {
                        setSelectedGroup(selected);
                        setIsWrong(prevState => ({...prevState, isGroup:false}));
                        setSelectedCategory({label: "", value: ""});
                    }}
                    error={isWrong.isGroup}
                    errorText="*Группа не выбрана"
                />
            </div>

            <div className="media-modal-dropdown">
                <label className="media-modal-label">Категории:</label>
                    <Dropdown
                        options={categoryOptions}
                        defaultText="Выберите категорию"
                        selectedOpt={selectedCategory.label.length === 0 ? null : selectedCategory}
                        onSelect={(selected) => {
                            setSelectedCategory(selected); 
                            setIsWrong(prevState => ({...prevState, isCategory:false}))
                        }}
                        error={isWrong.isCategory}
                        errorText="*Категория не выбрана"
                    />
            </div>
            
            <div className="media-modal-buttons">
                <Button
                    text={publicationForEdit ? "Сохранить" : "Создать"}
                    blueColor
                    onClick={() => handleSubmit()}
                />
                <Button
                    text="Отменить"
                    onClick={() => closeModal()}
                />
            </div>
        </div>
    );
};

export default MediaForm;