import React, { useEffect, useState, useRef } from "react";
import AnalyticsTable from "./AnalyticsTable";
import { AkimMention } from "../Icons";
import {
    getAnalytics,
    getDistricts,
    getMediaType,
    createParsingEvent,
    updateParsingEvent,
} from "../../services";
import {
    IAnalyticsPublication,
    IMediaType,
    IDistricts,
    IOption,
    IParsingEvent,
    IOrganizations,
} from "../../types";
import Pagination from "../UI/Pagination";
import Input from "../UI/Input";
import InputRangeDate from "../UI/InputRangeDate";
import { format } from "date-fns";
import Title from "../Title";
import Modal from "../UI/Modal";
import { Cancel } from "../Icons";
import Button from "../UI/Button";
import DatePicker from "../UI/DatePicker";
import dayjs from "dayjs";
import { getOrganizations } from "../../services";
import { Simulate } from "react-dom/test-utils";
import select = Simulate.select;

const AnalyticsPage = () => {
    Title("Пульс общества");
    const [organizers, setOrganizers] = useState<IOrganizations[]>([]);
    const [selectedOrganizer, setSelectedOrganizer] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [publications, setPublications] = useState<IAnalyticsPublication[]>(
        []
    );
    const [page, setPage] = useState<number>(0);
    const [searchParam, setSearchParam] = useState<string>("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [districts, setDistricts] = useState<IDistricts[]>([]);
    const [mediaType, setMediaType] = useState<IMediaType[]>([]);
    const [selectedDistricts, setSelectedDistricts] = useState<IOption[]>([]);
    const [selectedMediaType, setSelectedMediaType] = useState<IOption[]>([]);

    const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedPublication, setSelectedPublication] =
        useState<IAnalyticsPublication | null>(null);
    const [coords, setCoords] = useState<DOMRect | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [isCriticalSetted, setIsCrititcalSetted] = useState(false);

    const perPage = 15;
    const datePickerContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getOrganizations();
            if (!result.isError && result.data) {
                setOrganizers(result.data);
            } else {
                console.log("error");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        loadDistricts();
        loadMediaType();
    }, []);

    useEffect(() => {
        fetchAnalytic(1);
    }, [searchParam, startDate, endDate, selectedDistricts, selectedMediaType, isCriticalSetted]);

    const fetchAnalytic = async (page: number) => {
        let start = startDate ? format(startDate, "yyyy-MM-dd") : "";
        let end = endDate ? format(endDate, "yyyy-MM-dd") : "";
        let districtsId = selectedDistricts.map((el) => el.value);
        let mediaType = selectedMediaType.map((el) => el.value);
        const response = await getAnalytics(
            page,
            perPage,
            searchParam,
            districtsId.join(","),
            mediaType.join(","),
            start,
            end,
            isCriticalSetted ? isCriticalSetted : ""
        );
        if (!response.isError) {
            setPublications(response.data.results);
            setTotalCount(response.data.count);
            setPage(page);
        } else {
            setPublications([]);
        }
    };

    const loadDistricts = async () => {
        const response = await getDistricts();
        if (!response.isError) {
            setDistricts(response.data);
        } else {
            console.log("error");
        }
    };

    const loadMediaType = async () => {
        const response = await getMediaType();
        if (!response.isError) {
            setMediaType(response.data);
        } else {
            console.log("error");
        }
    };

    const createEvent = async () => {
        if (
            selectedPublication &&
            selectedDate &&
            selectedOrganizer &&
            comment
        ) {
            let parsingData: IParsingEvent = {
                name: selectedPublication.topic,
                date: dayjs(selectedDate).format("YYYY-MM-DD"),
                url: selectedPublication.url,
                organization_id: selectedOrganizer,
                comment: comment,
                source_name: selectedPublication.media_name,
                publication_id: selectedPublication.id,
            };
            let response =
                selectedPublication.parsing_event_id === null
                    ? await createParsingEvent(parsingData)
                    : await updateParsingEvent(
                          {
                              date: parsingData.date,
                              organization_id: selectedOrganizer,
                              comment: comment,
                          },
                          selectedPublication.parsing_event_id
                      );
            setSelectedPublication(null);
            setSelectedDate(new Date());
            setSelectedOrganizer(0);
            setComment("");

            if (!response.isError) {
                console.log("created");
            } else {
                console.log("error");
            }
        }
        setIsOpenModal(false);
        fetchAnalytic(page);
    };

    const handleClickAddButton = (
        publication: IAnalyticsPublication,
        coord: DOMRect
    ) => {
        setSelectedPublication(publication);
        setIsOpenCalendar(true);
        setCoords(coord);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            datePickerContainer.current &&
            !datePickerContainer.current.contains(event.target as Node)
        ) {
            setIsOpenCalendar(false);
        }
    };

    const closeAddModal = () => {
        setIsOpenModal(!isOpenModal);
        setSelectedDate(new Date());
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <div className="analytics">
            <div className="analytics-header">
                <h2 className="analytics-title">Пульс общества</h2>
            </div>

            <div className="analytics-filters">
                <div className="analytics-filters-input">
                    <Input
                        value={searchParam}
                        placeholder="Поиск по теме"
                        className="search-input"
                        onChange={(e) => setSearchParam(e)}
                    />
                </div>
                <div className="analytics-filters-date-select">
                    <button
                        className="akim-mention"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            border: 0,
                            padding: "8px",
                            cursor: "pointer",
                            background: isCriticalSetted
                                ? "indianred"
                                : "transparent",
                            color: isCriticalSetted ? "#fff" : "#000",
                            borderRadius: "8px",
                        }}
                        onClick={() => setIsCrititcalSetted((val) => !val)}
                    >
                        <AkimMention
                            width="16"
                            height="16"
                            fill={isCriticalSetted ? "#fff" : "indianred"}
                        />
                        <div>Упоминания</div>
                    </button>
                    <div className="analytics-filters-date-select-from">
                        <p>от</p>
                        <div className="date-picker-wrapper">
                            <InputRangeDate
                                selectedStartDate={startDate}
                                selectedEndDate={endDate}
                                type="start"
                                onSelect={(date) => setStartDate(date)}
                                onResetSelectedDate={() => setEndDate(null)}
                            />
                        </div>
                    </div>
                    <div className="analytics-filters-date-select-to">
                        <p>до</p>
                        <div className="date-picker-wrapper">
                            <InputRangeDate
                                selectedStartDate={startDate}
                                selectedEndDate={endDate}
                                type="end"
                                onSelect={(date) => setEndDate(date)}
                                onResetSelectedDate={() => setStartDate(null)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {publications && (
                <>
                    <AnalyticsTable
                        publications={publications}
                        districts={districts}
                        media={mediaType}
                        selectedDistricts={selectedDistricts}
                        selectedMedia={selectedMediaType}
                        onSelectDistricts={(selected) =>
                            setSelectedDistricts(selected)
                        }
                        onSelectMedia={(selected) =>
                            setSelectedMediaType(selected)
                        }
                        onButtonClick={(publication, coord) =>
                            handleClickAddButton(publication, coord)
                        }
                    />
                    <Pagination
                        totalCount={totalCount}
                        currentPage={page}
                        perPage={perPage}
                        onChange={(number) => fetchAnalytic(number)}
                    />
                </>
            )}
            {isOpenCalendar && (
                <div
                    className="analytics-date-picker-container"
                    ref={datePickerContainer}
                    style={{
                        top: coords
                            ? window.innerHeight - coords.top - 30 < 305
                                ? coords.top +
                                  window.scrollY -
                                  305 +
                                  coords.height
                                : coords.top + window.scrollY
                            : 0,
                        left: coords ? coords.left - 320 : 0,
                    }}
                >
                    <DatePicker
                        onSelect={(date) => {
                            setIsOpenModal(true);
                            setSelectedDate(date);
                        }}
                        onClose={() => setIsOpenCalendar(false)}
                        selectedDate={
                            selectedPublication &&
                            selectedPublication.event_date !== null
                                ? new Date(selectedPublication.event_date)
                                : selectedDate
                        }
                    />
                </div>
            )}
            {isOpenModal && (
                <Modal isOpen={isOpenModal} isSmallModal>
                    <div className="add-modal">
                        <div
                            className="add-modal-close-icon"
                            onClick={closeAddModal}
                        >
                            <Cancel color="#7A7A7A" />
                        </div>
                        <div className="add-modal-content">
                            <p className="add-modal-content-title">
                                {selectedPublication?.event_date === null
                                    ? "Назначить мероприятие:"
                                    : "Изменить дату мероприятия:"}
                            </p>
                            <p className="add-modal-content-subtitle-name">
                                {selectedPublication?.topic}
                            </p>
                            <label
                                htmlFor="add-modal-content-subtitle-name"
                                className="add-modal-content-title"
                            >
                                Ответственный:
                            </label>
                            <div className="select-wrapper">
                                <select
                                    className="add-modal-content-organizer"
                                    value={String(selectedOrganizer)}
                                    onChange={(e) =>
                                        setSelectedOrganizer(
                                            Number(e.target.value)
                                        )
                                    }
                                >
                                    <option value="0" disabled>
                                        Выбрать организатора
                                    </option>

                                    {organizers.map((org) => (
                                        <option key={org.id} value={org.id}>
                                            {org.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <label
                                htmlFor="add-modal-content-subtitle-comment"
                                className="add-modal-content-title"
                            >
                                Комментарий:
                            </label>
                            <textarea
                                className="add-modal-content-comment"
                                id="comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Введите Ваш комментарий"
                            />
                            <p className="add-modal-content-subtitle-date">
                                на {dayjs(selectedDate).format("DD.MM.YYYY")}
                            </p>
                        </div>
                        <div className="add-modal-content-btns">
                            <Button text="НЕТ" onClick={closeAddModal} big />
                            <Button
                                text="ДА"
                                onClick={createEvent}
                                big
                                blueColor
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default AnalyticsPage;
