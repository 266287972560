import React, {useEffect, useState} from 'react';
import {DayPicker} from "react-day-picker";
import 'react-day-picker/dist/style.css';
import {ru} from "date-fns/locale";

interface IDateRange {
    selectedStartDate: null | Date;
    selectedEndDate: null | Date;
    type: "start" | "end";
    onSelect: (date: Date) => void;
    onResetSelectedDate: () => void;
}

const DateRange: React.FC<IDateRange> = ({selectedStartDate, selectedEndDate, type, onSelect, onResetSelectedDate}) => {
    const [selectedDay, setSelectedDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (type === "start") {
            setSelectedDate(selectedStartDate ? selectedStartDate : undefined);
        }
        else {
            setSelectedDate(selectedEndDate ? selectedEndDate : undefined);
        }
        styles();
    }, []);

    const checkDates = (date: Date) => {
        if (type === "end" && selectedStartDate && date.setHours(0,0,0,0) < selectedStartDate.setHours(0,0,0,0)) {
                onResetSelectedDate();
        }
        if (type === "start" && selectedEndDate && date.setHours(0,0,0,0) > selectedEndDate.setHours(0,0,0,0)) {
            onResetSelectedDate();
        }
    }

    const setParentsStyle = (childElement: Element | null, type: string) => {
        if (childElement) {
            const parentElementStart = childElement.parentElement;
            if (parentElementStart) {
                parentElementStart.style.backgroundColor = 'rgba(30, 118, 232, 0.1)';
                parentElementStart.style.borderRadius = type === "start" ? '50% 0 0 50%' : '0 50% 50% 0';
            }
        }
    }

    const styles = () => {
        if (selectedStartDate && selectedEndDate) {
            const childElementStart = document.querySelector('.date-selected-start');
            const childElementEnd = document.querySelector('.date-selected-end');
            setParentsStyle(childElementStart, "start");
            setParentsStyle(childElementEnd, "end");
        }
    }

    const modifiersClassNames = {
        selectedDatesStart: "date-selected-start",
        selectedDatesEnd: "date-selected-end",
        rangeDate: "date-range"
    };

    const modifiers = {
        selectedDatesStart: (date: Date) =>
            !!(
                (selectedStartDate && date.setHours(0, 0, 0, 0) === selectedStartDate.setHours(0, 0, 0, 0))
            ),
        selectedDatesEnd: (date: Date) =>
            !!(
                (selectedEndDate && date.setHours(0, 0, 0, 0) === selectedEndDate.setHours(0, 0, 0, 0))
            ),
        rangeDate: (date: Date) =>
            !!(
                selectedStartDate &&
                selectedEndDate &&
                date.setHours(0, 0, 0, 0) > selectedStartDate.setHours(0, 0, 0, 0) &&
                date.setHours(0, 0, 0, 0) < selectedEndDate.setHours(0, 0, 0, 0)
            ),
    };

    const onSelectDate = (date: Date) => {
        checkDates(date);
        setSelectedDate(date);
        onSelect(date);
    };

    const handleMonthChange = () => {
        setTimeout(() => {
          styles();
        }, 0);
      };

    return (
        <div className="date-picker">
            <DayPicker
                mode="single"
                selected={selectedDay}
                onDayClick={(date) => onSelectDate(date)}
                locale={ru}
                showOutsideDays
                modifiers={modifiers}
                modifiersClassNames={modifiersClassNames}
                onMonthChange={handleMonthChange}
            />
        </div>
    );
};

export default DateRange;