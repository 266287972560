import React from 'react';

interface IButton {
    text: string;
    onClick: (e: React.MouseEvent) => void;
    big?: boolean;
    blueColor?: boolean;
    redColor?: boolean;
    fontStyle?: any;
}

const Button: React.FC<IButton> = ({ text, onClick, big, blueColor, redColor, fontStyle }) => {
    return (
        <div
            className={`button ${blueColor ? 'color-blue' : ''} ${big ? 'button-big' : ''} ${redColor ? 'color-red' : ''}`}
            onClick={onClick}
            style={fontStyle? fontStyle: {}}
        >
            {text}
        </div>
    );
};

export default Button;