import React, { ReactNode, useState } from "react";
import { annualCalendarList } from "../data/dropdownValues";
import { IOption } from "../types";

interface DateContextProvider {
   children: ReactNode;
}

interface IDateContext {
   selectedDate: Date;
   selectedYear: string;
   calendarPeriod: IOption;
   setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
   setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
   setCalendarPeriod: React.Dispatch<React.SetStateAction<IOption>>;
}

export const DateContext = React.createContext<IDateContext>({
   selectedDate: new Date(),
   selectedYear: ((new Date()).getFullYear()).toString(),
   calendarPeriod: annualCalendarList[0],
   setSelectedYear: () => {},
   setSelectedDate: () => {},
   setCalendarPeriod: () => {},
});

export const DateContextProvider: React.FC<DateContextProvider> = ({children}) => {
   const [selectedDate, setSelectedDate] = useState<Date>(sessionStorage.getItem("selectedDay") ?
      new Date(sessionStorage.getItem("selectedDay") as string
   ) : new Date());

   const [selectedYear, setSelectedYear] = useState<string>(((new Date()).getFullYear()).toString());

   const [calendarPeriod, setCalendarPeriod] = useState<IOption>(
      annualCalendarList[0]
   );

   return (
      <DateContext.Provider value={{selectedDate, setSelectedDate, selectedYear, setSelectedYear, calendarPeriod, setCalendarPeriod}}>
         {children}
      </DateContext.Provider>
   );
}