import React from 'react';
import {ArrowDown} from "../Icons";

interface IPagination {
    totalCount: number;
    currentPage: number;
    perPage: number;
    onChange: (number: number) => void;
}

const Pagination: React.FC<IPagination> = ({totalCount, currentPage, onChange, perPage}) => {
    const totalPages = Math.ceil(totalCount / perPage);

    const getPaginationNumbers = () => {
        const delta = 1;
        const range = [];
        const rangeWithDots = [];

        if (currentPage < 3) {
            for (let i = Math.max(2, delta); i <= Math.min(totalPages - 1, 4); i++) {
                range.push(i);
            }
        }
        else if (totalPages - currentPage < 3) {
            for (let i = Math.max(2, totalPages - 3); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                range.push(i);
            }
        }
        else {
            for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                range.push(i);
            }
        }

        if (currentPage - delta > 2 && totalPages > 5) {
            rangeWithDots.push(1);
            rangeWithDots.push('...');
        } else {
            rangeWithDots.push(1);
        }

        rangeWithDots.push(...range);

        if (currentPage + delta < totalPages - 1 && totalPages > 5) {
            rangeWithDots.push('...');
            rangeWithDots.push(totalPages);
        } else {
            if (totalPages > 1) {
                rangeWithDots.push(totalPages);
            }
        }

        return rangeWithDots;
    };

    const paginationNumbers = getPaginationNumbers();

    const handlePrev = () => {
        if (currentPage > 1) {
            onChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onChange(currentPage + 1);
        }
    };

    return (
        <div className="pagination-wrapper">
            <div className="pagination-navigation">
                <div className="pagination-navigation-prev" onClick={handlePrev}>
                    <ArrowDown />
                </div>
                <div className="pagination-navigation-next" onClick={handleNext}>
                    <ArrowDown />
                </div>
            </div>

            <div className="pagination-pages">
                {paginationNumbers.map((number, index) => (
                    <button
                        key={index}
                        onClick={() => typeof number === 'number' && onChange(number)}
                        disabled={number === currentPage || typeof number !== 'number'}
                        className={number === currentPage ? 'active' : ''}
                    >
                        {number}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Pagination;