import React from "react";
import { GreenLineStatistic, RedLineStatistic } from "../Icons";

const StatisticEmpty = () => {

    return (
        <div className="statistic-empty">
            <div className="statistic-empty-icons">
                <GreenLineStatistic/>
                <RedLineStatistic/>
            </div>
            <p className="statistic-empty-title">Нет данных</p>
            <p className="statistic-empty-subtitle">К сожалению, пока у вас нет статистики за выбранные год</p>
        </div>
    );
};

export default StatisticEmpty;