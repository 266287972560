import React, { useState, useRef, useEffect, useContext } from 'react';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import { Calendar } from "../Icons";
import DatePicker from "./DatePicker";
import { DateContext } from '../../contexts/DateContext';

interface InputDatePickerProps {
    value: Date | undefined;
    onChange: (date: Date | undefined) => void;
    label?: string | null;
    error?: boolean;
    errorText?: string;
}

const InputDatePicker: React.FC<InputDatePickerProps> = ({ value, onChange, label, error, errorText }) => {
    const {selectedDate} = useContext(DateContext);
    const [selectedDay, setSelectedDay] = useState<Date>(value ? value : selectedDate);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    const handleDayClick = (day: Date) => {
        setSelectedDay(day);
        onChange(day);
    };

    const handleClick = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setIsCalendarOpen(false);
        }
    };

    useEffect(() => {
        if (isCalendarOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isCalendarOpen]);

    return (
        <div ref={inputRef} className="input-date-picker">
            {label && <label className="input-date-picker-label">{label}</label>}
            <div className="input-date-picker-input-container">
                <input
                    type="text"
                    value={selectedDay ? format(selectedDay, 'dd.MM.yyyy') : ''}
                    onClick={handleClick}
                    className={`input-date-picker-input ${error ? 'input-date-picker-input-error' : ''}`}
                    placeholder="__.__.__"
                    readOnly
                />
                <span onClick={handleClick}>
                    <Calendar />
               </span>
            </div>
            {isCalendarOpen && (
                <div className="input-date-picker-calendar">
                    <DatePicker
                        onSelect={handleDayClick}
                        onClose={handleClick}
                        selectedDate={selectedDay}
                    />
                </div>
            )}
            {error && errorText && <div className="input-date-picker-error-text">{errorText}</div>}
        </div>
    );
};

export default InputDatePicker;
