import React from 'react';
import { IEventsDevidedByDays } from '../../types/events';

interface ICalendarWeekHeader {
    weekWithEvent: IEventsDevidedByDays[];
}

const CalendarWeekHeader: React.FC<ICalendarWeekHeader> = ({weekWithEvent}) => {
    return (
        <div className="calendar-header">
            {weekWithEvent.map((el: any) =>
                <div className={"date-item-wrapper"} key={el.key}>
                    <div className={`date-item ${el.className}`}>
                        <p className="date-item-weekday">{el.key}</p>
                        <p className="date-item-day">{el.date.getDate()}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendarWeekHeader;