import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import { ArrowDown } from "../Icons";

interface AccordionProps {
    checkedAll: boolean;
    title: string;
    isWithoutCheckboxTitle?: boolean;
    onCheckedAll?: (status: string) => void;
    children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ checkedAll, title, children, onCheckedAll, isWithoutCheckboxTitle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [allSelected, setAllSelected] = useState(checkedAll);

    useEffect(() => {
        setAllSelected(checkedAll);
    }, [checkedAll]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckAllChange = (status: string) => {
        if (onCheckedAll) {
            onCheckedAll(status);
        }
    };

    return (
        <div className="accordion">
            <div className="accordion-header">
                {!isWithoutCheckboxTitle
                ?
                <Checkbox
                    label={title}
                    isChecked={allSelected}
                    onChange={handleCheckAllChange}
                    labelStyle={{ fontSize: "16px", color: "#7A7A7A" }}
                />
                :
                <p className="accordion-header-title">{title}</p>
                }
                <div onClick={toggleAccordion}>
                    <ArrowDown className={`arrow ${isOpen ? "rotate-180" : ""}`} width="14" height="14" />
                </div>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default Accordion;
