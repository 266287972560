import React, { useState, useRef, useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';
import { Calendar } from "../Icons";
import DatePicker from "./DatePicker";
import DateRange from './DateRange';

interface InputDatePickerProps {
    selectedStartDate: null | Date;
    selectedEndDate: null | Date;
    type: "start" | "end";
    onSelect: (date: Date) => void;
    onResetSelectedDate: () => void;
}

const InputRangeDate: React.FC<InputDatePickerProps> = ({selectedStartDate, selectedEndDate, type, onSelect, onResetSelectedDate }) => {
    const [selectedDay, setSelectedDay] = useState<Date | null>( null );
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    const handleDayClick = (day: Date) => {
        setSelectedDay(day);
        onSelect(day);
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleClick = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setIsCalendarOpen(false);
        }
    };

    useEffect(() => {
        if (isCalendarOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isCalendarOpen]);

    useEffect(() => {
        if ( type === "start") {
            setSelectedDay(selectedStartDate);
        }
        else {
            setSelectedDay(selectedEndDate);
        }
    }, [selectedStartDate, selectedEndDate]);

    return (
        <div ref={inputRef} className="input-date-picker">
            <div className="input-date-picker-input-container">
                <input
                    type="text"
                    value={selectedDay ? format(selectedDay, 'dd.MM.yy') : ''}
                    onClick={handleClick}
                    className={`input-date-picker-input`}
                    placeholder="__.__.__"
                    readOnly
                />
                <span onClick={handleClick}>
                    <Calendar />
               </span>
            </div>
            {isCalendarOpen && (
                <div className="input-date-picker-calendar range-calendar" style={{width: "310px", left: "auto", right: 0}}>
                    <DateRange
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    type={type}
                    onSelect={handleDayClick}
                    onResetSelectedDate={onResetSelectedDate}/>
                </div>
            )}
        </div>
    );
};

export default InputRangeDate;
