import { useContext } from "react";
import { showMonthByNumber } from "../../data/months";
import { DateContext } from "../../contexts/DateContext";
import { IOption } from "../../types";
import { ArrowLeft, ArrowRight } from "../Icons";
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';

interface ISliderProps {
   calendarPeriod: IOption;
}

dayjs.extend(isoWeek);

const Slider: React.FC<ISliderProps> = ({calendarPeriod}) => {
   const {selectedDate, setSelectedDate} = useContext(DateContext);

   const localTimezone = (date: Date): Date => {
      const offset = date.getTimezoneOffset();
      const localDate = new Date(date.getTime() - offset * 60 * 1000);
      return localDate;
   }

   const goToPreviousWeek = () => {
      const newDate = new Date(selectedDate);
      if(calendarPeriod.value === "week"){
         newDate.setDate(selectedDate.getDate() - 7);
      } else if(calendarPeriod.value === "month"){
         newDate.setMonth(selectedDate.getMonth() - 1);
      } else {
         newDate.setFullYear(selectedDate.getFullYear() - 1);
      }
      setSelectedDate(newDate);
   };

   const goToNextWeek = () => {
      const newDate = new Date(selectedDate);
      if(calendarPeriod.value === "week"){
         newDate.setDate(selectedDate.getDate() + 7);
      } else if(calendarPeriod.value === "month"){
         newDate.setMonth(selectedDate.getMonth() + 1);
      } else {
         newDate.setFullYear(selectedDate.getFullYear() + 1);
      }
      setSelectedDate(newDate);
   };

   let content;

   if (calendarPeriod.value === "week"){
      content = <>
                  <span>{dayjs(localTimezone(selectedDate)).isoWeek()} неделя</span>
                  <span>{localTimezone(selectedDate).getFullYear()}</span>
               </>
   } else if (calendarPeriod.value === "month") {
      content = <>
                  <span>{showMonthByNumber(localTimezone(selectedDate).getMonth()).toLocaleLowerCase()}</span>
                  <span>{localTimezone(selectedDate).getFullYear()}</span>
               </>
   } else {
      content = <span>{localTimezone(selectedDate).getFullYear()} год</span>
   }

   return (
      <div className="header-slider">
         <div className="header-slider-nav">
            <button onClick={goToPreviousWeek}><ArrowLeft /></button>
               <div className="header-slider-date">
                  {content}
               </div>
            <button onClick={goToNextWeek}><ArrowRight/></button>
         </div>
      </div>
   )
}

export default Slider;