import { useCallback, useEffect, useMemo, useState } from "react";
import InputDatePicker from "../UI/InputDate";
import ReactWordcloud from "react-wordcloud";
import { IWordCloudItem } from "../../types";
import { getWordCloud } from "../../services";
import { format } from "date-fns";
import Title from "../Title";

export const WordCloudPage = () => {
    Title('Тренды обсуждений')
    const [data, setData] = useState<IWordCloudItem[]>([]);
    const [loading, setLoading] = useState(false);

    const [dates, setDates] = useState({
        start: new Date(new Date().setMonth(new Date().getMonth() - 1)) as
            | undefined
            | Date,
        end: new Date() as undefined | Date,
    });

    const [selectedDistrict, setSelectedDistrict] = useState<string[]>([]);

    const districtNames = useMemo(
        () => data.map(({ district: n }) => n),
        [data]
    );

    const checkIsSelected = useCallback(
        (districtName: string) => {
            return selectedDistrict.includes(districtName);
        },
        [selectedDistrict]
    );

    const toggleDistrictSelect = useCallback(
        (districtName: string) => {
            if (!selectedDistrict.includes(districtName)) {
                return setSelectedDistrict([...selectedDistrict, districtName]);
            }

            setSelectedDistrict(
                selectedDistrict.filter((v) => v !== districtName)
            );
        },
        [selectedDistrict, setSelectedDistrict]
    );

    const fetchData = useCallback(async () => {
        if (!dates.start || !dates.end) {
            return;
        }

        setLoading(true);

        const response = await getWordCloud(
            format(dates.start, "yyyy-MM-dd"),
            format(dates.end, "yyyy-MM-dd")
        );

        if (!response.isError) {
            setData(response.data);
        }

        if (response.isError) {
            setData([]);
        }
        setLoading(false);
    }, [setData, setLoading, dates]);

    useEffect(() => {
        fetchData();
    }, [dates]);

    const getComputedData = (data: IWordCloudItem[]) => {
        const words = new Map() as Map<string, { text: string; value: number }>;

        data.filter(({ district: n }) =>
            selectedDistrict.length ? selectedDistrict.includes(n) : true
        ).forEach((district) => {
            district.word_frequencies.forEach(
                ({ word_frequency, word_name }) => {
                    words.set(word_name, {
                        text: word_name,
                        value:
                            (words.get(word_name)?.value ?? 0) + word_frequency,
                    });
                }
            );
        });

        return Array.from(words)
            .map((arr) => arr[1])
            .filter(({ value: l }) => l > 5);
    };

    const callbacks = {
        onWordClick: console.log,
    } as any;
    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
    } as any;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "16px 0",
            }}
        >
            <h2 className="analytics-title">{"Тренды обсуждений"}</h2>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "16px",
                    padding: "16px 0",
                }}
            >
                <InputDatePicker
                    value={dates.start ?? undefined}
                    onChange={(date) => setDates({ ...dates, start: date })}
                />

                <InputDatePicker
                    value={dates.end ?? undefined}
                    onChange={(date) => setDates({ ...dates, end: date })}
                />
            </div>

            {loading && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {"Загрузка..."}
                </div>
            )}

            {!loading && data.length && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px",
                            marginBottom: "24px",
                        }}
                    >
                        {districtNames.map((districtName) => {
                            return (
                                <button
                                    type="button"
                                    style={{
                                        border: 0,
                                        padding: "6px 16px",
                                        backgroundColor: !checkIsSelected(
                                            districtName
                                        )
                                            ? "#dfe6e9"
                                            : "#2d3436",
                                        color: !checkIsSelected(districtName)
                                            ? "#2d3436"
                                            : "#dfe6e9",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        toggleDistrictSelect(districtName)
                                    }
                                >
                                    {districtName || "Непривязанные"}
                                </button>
                            );
                        })}
                    </div>

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "400px",
                        }}
                    >
                        <ReactWordcloud
                            callbacks={callbacks}
                            options={{
                                ...options,

                                fontSizes: [32, 64],
                                padding: 4,
                            }}
                            words={getComputedData(data)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
