export const getUserRole = (): string => {
    const authDataString = localStorage.getItem("authData");
    if (authDataString) {
        const authData = JSON.parse(authDataString);
        if (authData && authData.user_group) {
            return authData.user_group;
        }
    }
    return "";
};

export const isAdmin = () => {
    return getUserRole() === "SuperUserGroup";
}

export const isEditor = () => {
    return getUserRole() === "EditorGroup";
}

export const isViewer = () => {
    return getUserRole() === "ViewerGroup";
}