import { useEffect, useState } from "react";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { IOption, IUser, IUserGroup, IUserPermission } from "../../types";
import { createUsers, getGroups } from "../../services";
import Dropdown from "../UI/Dropdown";

interface IUserForm {
  closeModal: () => void;
  onSubmit: () => void;
  headLabel?: string;
  isEdit?: boolean;
  user?: IUser;
  onSave?: (user: IUser) => void;
}

const UsersForm: React.FC<IUserForm> = ({ 
  closeModal, 
  onSubmit, 
  headLabel, 
  isEdit, 
  user, 
  onSave,
}) => {
  const [name, setName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isWrong, setIsWrong] = useState<{isName: boolean, isMail: boolean, isPassword: boolean, isGroup: boolean}>({isName: false, isMail: false, isPassword: false, isGroup: false});
  const [groups, setGroups] = useState<IOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IOption | null>(null);

  const TOKEN = JSON.parse(localStorage.getItem("authData") as string).auth_token;

  const loadGroups = async() => {
    const response = await getGroups(TOKEN);
    if (!response.isError) {
      const receivedGroups: IUserGroup[] = response.data;
      const options: IOption[] = receivedGroups.map((object: IUserGroup) => ({
        label: object.verbose_name,
        value: object.id,
      }));
      setGroups(options);
    }
  }

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.full_name as string);
      setMail(user.email as string);
      setSelectedGroup({label: user.group?.verbose_name as string, value: user.group?.id as number})
    }
  }, [user]);

  const isValidEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const handleSubmit = async () => {
    let validation;
    
    if (name.length > 0 && isValidEmail(mail) && selectedGroup) {
      
      const data = {
        id: user?.id,
        full_name: name,
        email: mail,
        password: password,
        group_id: selectedGroup?.value,
      };

      validation = {
        isName: false,
        isMail: false,
        isPassword: isEdit ? false : password.length === 0,
        isGroup: false,
      };

      const isValid = !Object.values(validation).includes(true);

      if (isValid) {
        if (isEdit && onSave) {
          if ( data.password.length === 0) {
            const {password, ...rest} = data;
            onSave({ ...user!, ...rest });
          } else {
            onSave({ ...user!, ...data });
          }
        } else {
          const response = await createUsers(data, TOKEN);
          if (!response.isError) {
            onSubmit();
          }
        }
        setIsWrong({isName: false, isMail: false, isPassword: false, isGroup: false});
      } else {
        setIsWrong(validation);
      }
    } else {
      validation = {
        isName: name.trim().length === 0,
        isMail: !isValidEmail(mail),
        isPassword: isEdit ? false : password.trim().length === 0,
        isGroup: selectedGroup === null,
      };

      setIsWrong(validation);
    }
  };

  return (
    <div className="media-modal">
      <label className="media-modal-header">
        {headLabel ? headLabel : "Создать нового пользователя"}
      </label>
      <Input
        value={name}
        placeholder="Имя"
        label="Имя:"
        onChange={(value) => {
          setName(value);
          setIsWrong(prevState => ({...prevState, isName: false}));
        }}
        type="text"
        error={isWrong.isName}
        errorText="*Обязательное поле"
      />

      <Input
        value={mail}
        placeholder="example@mail.com"
        label="Почта:"
        onChange={(value) => {
          setMail(value);
          setIsWrong(prevState => ({...prevState, isMail: false}));
        }}
        type="email"
        error={isWrong.isMail}
        errorText="*Введите корректную почту"
      />

      <Input
        value={password}
        placeholder="Пароль"
        label="Пароль:"
        onChange={(value) => {
          setPassword(value);
          setIsWrong(prevState => ({...prevState, isPassword: false}));
        }}
        type="text"
        error={isWrong.isPassword}
        errorText="*Обязательное поле"
      />

      <div>
        <Dropdown
          label="Выберите доступ:"
          defaultText="Доступ"
          options={groups}
          selectedOpt={selectedGroup}
          onSelect={(value) => {
            setSelectedGroup(value);
            setIsWrong(prevState => ({...prevState, isGroup: false}))
          }}
          error={isWrong.isGroup}
          errorText="*Обязательное поле"
        />
      </div>

      <div className="media-modal-buttons">
        <Button text={isEdit ? "Сохранить" : "Создать"} 
                blueColor 
                onClick={handleSubmit} 
        />
        <Button text="Отменить" onClick={() => closeModal()} />
      </div>
    </div>
  );
};

export default UsersForm;