import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { getEvents, getParsingEvents, getStatuses } from '../../services';
import CalendarWeekHeader from './CalendarWeekHeader';
import CalendarCard from './CalendarCard';
import CalendarCardWithStatus from './CalendarCardWithStatus';
import { IEvent, IEventsDevidedByDays } from '../../types/events';
import { getOrganizations } from '../../services';
import { IOrganizations, IParsingEvent, IOption } from '../../types';
import dayjs from 'dayjs';

interface ICalendarWeek {
    selectedDate: Date;
    selectedMedia: number[];
    selectedOrganization: number | null;
    openEditModal: (event: IEvent | null) => void;
}

const CalendarWeek = forwardRef<any, ICalendarWeek>(({ selectedDate, openEditModal, selectedOrganization, selectedMedia }, ref) => {
    const [data, setData] = useState<IEvent[]>([]);
    const [dataParsing, setDataParsing] = useState<IParsingEvent[]>([]);
    const [weekData, setWeekData] = useState<IEventsDevidedByDays[]>([]);
    const [organizations, setOrganizations] = useState<IOrganizations[]>([]);
    const [gridItemWidth, setGridItemWidth] = useState<number>(0);
    const calendarWeekContainer = useRef<any>(null);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [statuses, setStatuses] = useState<IOption[]>([]);

    useEffect(() => {
        setStartEndDate();
    }, [selectedDate]);

    useEffect(() => {
        if (startDate && endDate) {
            loadEventData();
            if (selectedOrganization || selectedMedia.length !== 0) {
                setDataParsing([]);
            }
            else {
                loadParsingData();
            }
        }
    }, [startDate, endDate, selectedOrganization, selectedMedia]);

    useEffect(() => {
        setWeekData(getWeekFromSelectedDate(selectedDate));
    }, [selectedDate, data, dataParsing]);

    useEffect(() => {
        loadOrganizations();
        loadStatuses();
        calculateGridWidth();
    }, []);

    useImperativeHandle(ref, () => ({
        loadEventData,
    }));

    useEffect(() => {
        if (visualViewport) {
            visualViewport.addEventListener('resize', () => {
                calculateGridWidth();
            });
        }
        calculateGridWidth();
    }, []);

    const calculateGridWidth = () => {
        if (calendarWeekContainer.current) {
            const pageWidth = window.innerWidth;
            const cardRect = calendarWeekContainer.current.getBoundingClientRect();
            let widthOfGridItem = (pageWidth - cardRect.left) / 7;
            setGridItemWidth(widthOfGridItem);
        }
    }

    const compareDate = (firstDate: Date, secondDate: Date, param: "isMore" | "isEqual") => {
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(0, 0, 0, 0);
        let firstTimeFromDate = firstDate.getTime();
        let secondTimeFromDate = secondDate.getTime();
        if (param === "isMore") {
            return firstTimeFromDate >= secondTimeFromDate;
        }
        return firstTimeFromDate === secondTimeFromDate;
    };

    const loadEventData = async (): Promise<void> => {
        const response = await getEvents(startDate, endDate, selectedOrganization, selectedMedia);
        if (!response.isError) {
            setData(response.data);
        } else {
            console.log(response.error);
        }
    };

    const loadParsingData = async (): Promise<void> => {
        const response = await getParsingEvents(startDate, endDate);
        if (!response.isError) {
            setDataParsing(response.data);
        } else {
            console.log(response.error);
        }
    };

    const loadOrganizations = async (): Promise<void> => {
        const response = await getOrganizations();
        if (!response.isError) {
            setOrganizations(response.data);
        } else {
            console.log(response.error);
        }
    };

    const loadStatuses = async () => {
        let response = await getStatuses();
        if (!response.isError) {
            setStatuses(response.data.map(el => ({label: el.verbose_name, value: el.status})));
        } else {
            console.log(response.error);
        }
    };

    const setStartEndDate = () => {
        const dayOfWeek = selectedDate.getDay() ? selectedDate.getDay() : 7;
        const mondayDate = new Date(selectedDate);
        const sundayDate = new Date(selectedDate);
        mondayDate.setDate(selectedDate.getDate() - dayOfWeek + 1);
        sundayDate.setDate(selectedDate.getDate() - dayOfWeek + 7);
        setStartDate(dayjs(mondayDate).format("YYYY-MM-DD"));
        setEndDate(dayjs(sundayDate).format("YYYY-MM-DD"));
    };

    const getWeekFromSelectedDate = (selectedDate: Date) => {
        let weekday = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
        const currentDate = new Date();
        const dayOfWeek = selectedDate.getDay() ? selectedDate.getDay() : 7;
        const mondayDate = new Date(selectedDate);
        mondayDate.setDate(selectedDate.getDate() - dayOfWeek + 1);
        const weekDates: any = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(mondayDate);
            date.setDate(mondayDate.getDate() + i);
            const dateInfo = {
                key: weekday[i],
                date: date,
                className: `${dayOfWeek === i + 1 && "selected"} ${(!compareDate(date, currentDate, "isMore") ? "past" : "")}`,
                events: data.filter((el: IEvent) => compareDate(date, new Date(el.date), "isEqual")),
                parsingEvents: dataParsing.filter((el: IParsingEvent) => compareDate(date, new Date(el.date), "isEqual")),
            };
            weekDates.push(dateInfo);
        }
        return weekDates;
    };

    return (
        <div className="calendar-week" ref={calendarWeekContainer}>
            <CalendarWeekHeader weekWithEvent={weekData} />
            <div className="calendar-week-grid-container">
                {weekData.map((dayInfo: IEventsDevidedByDays) => (
                    <div className="calendar-week-grid-item" key={dayInfo.key} style={{width: gridItemWidth}}>
                        {dayInfo.events.length !== 0 || dayInfo.parsingEvents.length !== 0
                        ? (
                            <>
                            {dayInfo.events.map((mediaEvent) => (
                                <CalendarCard
                                    openEditModal={openEditModal}
                                    mediaEvent={mediaEvent}
                                    isPast={dayInfo.className.includes("past")}
                                    organization={organizations.find((el) => el.id === mediaEvent.organization_id)}
                                    onUpdate={loadEventData}
                                    key={mediaEvent.id}
                                />
                            ))}
                            {dayInfo.parsingEvents.map((parsingEvent) => (
                                <CalendarCardWithStatus
                                    isPast={dayInfo.className.includes("past")}
                                    event={parsingEvent}
                                    statuses={statuses}
                                    onUpdate={loadParsingData}
                                    key={parsingEvent.id}
                                />
                            ))}
                            </>
                        ) : (
                            <div className="calendar-week-grid-item-empty">
                                <p>
                                    нет
                                    <br />
                                    мероприятий
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default CalendarWeek;
