import React, {useState, useEffect, useRef} from 'react';
import { Cancel } from '../Icons';

interface TextAreaProps {
    value: string;
    isActive: boolean;
    onChange?: (status: string) => void;
    onCloseEditMode?: () => void;
    onSave?: (value: string) => void;
}

const TextArea: React.FC<TextAreaProps> = ({value, isActive, onChange, onCloseEditMode, onSave}) => {
    const [textAreaVal, setTextAreaVal] = useState<string>(value);
    const [isError, setIsError] = useState<boolean>(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [isUpdated, setIsUpdates] = useState<boolean>(false);

    useEffect(() => {
        if (textAreaRef.current && isActive) {
            textAreaRef.current.focus();
            textAreaRef.current.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }
        if (textAreaRef.current && !isActive) {
            textAreaRef.current.scrollTop = 0;
            if (!isUpdated) {
                setTextAreaVal(value);
                setIsError(false);
            }
        }
    }, [isActive, textAreaRef]);

    useEffect(() => {
        setRow();
    }, [textAreaVal])

    const handleChange = (updateVal: string) => {
        setTextAreaVal(updateVal);
        setIsError(false);
        setIsUpdates(false);
    };

    const setRow = () => {
        if(textAreaRef.current) {
            textAreaRef.current.rows = 1;
            if (textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight) {
                textAreaRef.current.rows = 2;
            }
        }
    }

    const clearValue = () => {
        setTextAreaVal("");
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }

    const handleCancel = () => {
        setTextAreaVal(value);
        setIsError(false);
        if (onCloseEditMode) {
            onCloseEditMode();
        }
    }

    const handleSave = () => {
        if (textAreaVal) {
            value = textAreaVal;
            if (onCloseEditMode) {
                onCloseEditMode();
            }
            if (onSave) {
                onSave(textAreaVal);
            }
            setIsUpdates(true);
        }
        else {
            setIsError(true);
        }
    }

    return (
        <div>
            <div className="text-area-container">
                <textarea
                    className={`text-area ${isActive ? "active" : ""} ${isError? "error" : ""}`}
                    ref={textAreaRef}
                    rows={1}
                    value={textAreaVal}
                    disabled={!isActive}
                    onChange={(event) => handleChange(event.target.value)}
                />
                {isActive &&
                    <div className="cross-icon" onClick={clearValue}>
                        <Cancel bgColor={"#FFF"}/>
                    </div>
                }
            </div>
            {isActive &&
                <>
                    <div className={`hint-buttons-container ${isError? "error" : ""}`}>
                        {isError &&
                            <p className="error-message">
                                &#8727;Поле заголовка не может быть пустым. Введите заголовок.
                            </p>
                        }
                        <div className="button-container">
                            <button className="area-button cancel" onClick={handleCancel}>отменить</button>
                            {!isError
                                &&
                                <button className="area-button save" onClick={handleSave}>сохранить</button>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default TextArea;
