import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../UI/Input";
import Title from "../Title";
import { signIn } from "../../services/index";

const AuthForm = () => {
   Title("Авторизация");
   const [login, setLogin] = useState<string>("");
   const [password, setPassword] = useState<string>("");
   const [isCorrect, setIsCorrect] = useState<boolean>(true);
   const navigate = useNavigate();

   useEffect(() => {
      if (localStorage.getItem("authData")) {
         navigate("/");
      }
   }, []);

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
      event.preventDefault();

      if (login.length > 0 && password.length > 0) {
         try {
            const tokens = await signIn(login, password);
            if(tokens.isError) {
               setIsCorrect(false);
            } else {
               setIsCorrect(true);
               navigate("/");
               localStorage.setItem("authData", JSON.stringify(tokens));
            }
         } catch (e) {
            throw e;
         }
      }
      else {
         setIsCorrect(false);
      }
   }

   return (
      <div className="auth-wrapper">
         <form className="auth-form" onSubmit={handleSubmit}>
            <label className="auth-header">Авторизация</label>

            <Input 
               value={login} 
               placeholder={"example@mail.com"}
               label={"Логин"}
               type="email" 
               onChange={(value) => setLogin(value)} 
            />

            <Input 
               value={password} 
               placeholder={""}
               label={"Пароль"} 
               type="password"
               onChange={(value) => setPassword(value)} 
            />

            {isCorrect ? null : <label className="auth-incorrect">Неверный логин или пароль</label>}
            
            <button className="auth-button" type="submit">Войти</button>
         </form>
      </div>
   )
}

export default AuthForm;