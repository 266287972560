import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import CalendarPage from "./components/CalendarPage/CalendarPage";
import AnalyticsPage from "./components/AnalyticsPage/AnalyticsPage";
import MediaPage from "./components/MediaPage/MediaPage";
import AuthForm from "./components/AuthorizationPage/AuthForm";
import UsersPage from "./components/UsersPage/UsersPage";
import StatiscticPage from "./components/StatisticPage/StatisticPage";
import { WordCloudPage } from "./components/WordCloudPage/WordCloudPage";
export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {index: true, element: <CalendarPage />},
            {
                path: "/analytics",
                element: <AnalyticsPage />
            },
            {
                path: "/statistics",
                element: <StatiscticPage/>
            },
            {
                path: "/media",
                element: <MediaPage />
            },
            {
                path: "/users",
                element: <UsersPage/>
            },
            {
                path: "/word-cloud",
                element: <WordCloudPage />
            },
        ]
    },
    {
        path: "/authorization",
        element: <AuthForm />
    }
]);