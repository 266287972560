import { useEffect, useState } from "react";
import { getUsers } from "../../services";
import UsersTable from "./UsersTable";
import { ITokenData, IUser } from "../../types";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import UsersForm from "./UsersForm";
import { useNavigate } from "react-router-dom";
import Title from "../Title";
import { isAdmin } from "../../data/role";

const UsersPage = () => {
    Title("Пользователи");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>([]);
    const navigate = useNavigate();

    let token: ITokenData | null;
    try {
        token = JSON.parse(localStorage.getItem("authData") as string);
        if (!token) {
            navigate("/authorization");
        }
    } catch (e) {
        localStorage.removeItem("authData");
        navigate("/authorization");
    }

    useEffect(() => {
      if (isAdmin()) {
         loadUsers();
      }
      else {
         navigate("/");
      }
    }, []);

    const loadUsers = async () => {
        const response = await getUsers(token?.auth_token as string);
        if (!response.isError) {
            setUsers(response.data);
        } else {
            console.error(response.error);
        }
    };

    return (
        <div className="media">
            {isAdmin() && (
                <>
                    <div className="media-header">
                        <h2 className="media-title">Пользователи</h2>
                        <div className="media-create">
                            <Button
                                text={"Создать"}
                                blueColor
                                onClick={() => setIsOpen(true)}
                            />
                        </div>
                    </div>

                    {users && (
                        <UsersTable users={users} loadUsers={loadUsers} />
                    )}

                    <Modal isSmallModal isOpen={isOpen}>
                        <UsersForm
                            closeModal={() => setIsOpen(false)}
                            onSubmit={() => {
                                loadUsers();
                                setIsOpen(false);
                            }}
                        />
                    </Modal>
                </>
            )}
        </div>
    );
};

export default UsersPage;
