import React from 'react';
import { Link } from "../Icons";

export interface IInput {
    value: string;
    placeholder: string;
    onChange: (e: string) => void;
    className?: string;
    label?: string;
    link?: boolean;
    error?: boolean;
    errorText?: string;
    type?: "email" | "password" | "text";
    required?: boolean;
}

const Input: React.FC<IInput> = ({ value, placeholder, onChange, label, link, error, errorText, type, required, className}) => {
    return (
        <div className="input">
            {label &&
                <label className="input-label">{label}</label>
            }
            {link ? (
                <div className="input-wrapper">
                    <input
                        value={value}
                        className={`input-field ${error ? 'input-field-error' : ''}`}
                        placeholder={placeholder}
                        type={type}
                        required={required}
                        onChange={(event) => onChange(event.target.value)}
                    />
                    <Link />
                </div>
            ) : (
                <input
                    value={value}
                    className={`input-field ${error ? 'input-field-error' : ''} ${className ? className: ""}`}
                    placeholder={placeholder}
                    type={type}
                    required={required}
                    onChange={(event) => onChange(event.target.value)}
                />
            )}
            {error && errorText && <div className="error-text">{errorText}</div>}
        </div>
    );
};

export default Input;
