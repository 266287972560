import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom';

interface IModal {
    children: ReactNode;
    isOpen: boolean;
    isSmallModal?: boolean
    style?: any
}

const Modal: React.FC<IModal> = ({ isOpen, children, isSmallModal, style }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className={isSmallModal ? "modal-small" : "modal"} style={style}>
                {children}
            </div>
        </div>,
        document.getElementById('modal-root')!
    );
};

export default Modal;