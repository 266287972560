import React, { useEffect, useState, useContext, useMemo } from "react";
import StatisticChart from "./StatisticChart";
import {
    getStatisticData,
    getDocxReport,
    getOrganizationStatictis,
} from "../../services";
import StatisticEmpty from "./StatisticEmpty";
import { DateContext } from "../../contexts/DateContext";
import Title from "../Title";
import { Cancel, WordIcon } from "../Icons";
import Modal from "../UI/Modal";
import Dropdown from "../UI/Dropdown";
import { monthsOptions, statisticYears } from "../../data/dropdownValues";
import { IOption } from "../../types";
import Button from "../UI/Button";
import { stat } from "fs";
import { hr } from "date-fns/locale";

const StatiscticPage = () => {
    Title("Статистика");
    const [isOpenExportModal, setIsOpenExportModal] = useState<boolean>(false);
    const [notEmpty, setNotEmpty] = useState<boolean | null>(null);
    const [year, setYear] = useState<IOption>(statisticYears[0]);
    const [month, setMonth] = useState<IOption>(monthsOptions[0]);
    const { selectedYear } = useContext(DateContext);

    const [organizationStatistics, setOrganizationStatistics] = useState<
        Array<{
            organization_name: string;
            total_count: number;
            done_count: number;
            not_done_count: number;
        }>
    >([]);

    const loadOrganizationStatistics = async () => {
        const response = await getOrganizationStatictis(String(year.value));

        if (!response.isError) {
            setOrganizationStatistics(response.data);
        }

        if (response.isError) {
            setOrganizationStatistics([]);
        }
    };

    useEffect(() => {
        setNotEmpty(null);
        loadYearStatisticData(selectedYear);
        loadOrganizationStatistics();
    }, [selectedYear]);

    const loadYearStatisticData = async (year: string) => {
        const result = await getStatisticData("year", year);
        setNotEmpty(true);
        if (!result.isError && !Array.isArray(result.data)) {
            setNotEmpty(result.data.total_count !== 0);
        }
    };

    const downloadFile = async () => {
        const token = localStorage.getItem("authData")
            ? JSON.parse(localStorage.getItem("authData") as string).auth_token
            : null;
        if (token) {
            const response = await getDocxReport(
                token,
                Number(year.value),
                Number(month.value)
            );
            if (!response.isError) {
                const fileUrl = window.URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute("download", "report.docx");
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            } else {
                console.error(
                    "Произошла ошибка при скачивании файла:",
                    response.error
                );
            }
            setIsOpenExportModal(false);
        }
    };

    const sortedOrganizationStatistics = useMemo(() => {
        return organizationStatistics
            .map(
                ({
                    organization_name: on,
                    total_count: tc,
                    done_count: dc,
                }) => ({
                    name: on,
                    total: tc,
                    done: dc,
                    procent: Math.floor((dc / tc) * 100),
                })
            )
            .sort((a, b) => b.procent - a.procent);
    }, [organizationStatistics]);

    /* prettier-ignore */
    let periods = [
        "sources", 
        // "year", 
        // "semester", 
        // "quarter", 
        "month", 
        // "week",
    ];
    return (
        <>
            <div className="statistic">
                <div className="statistic-header">
                    <h1>Статистика</h1>
                    <div className="statistic-header-status">
                        <div className="completed-mark"></div>
                        <p>Исполненные</p>
                    </div>
                    <div className="statistic-header-status">
                        <div className="not-completed-mark"></div>
                        <p>Неисполненные</p>
                    </div>
                    {/* <button
                        className="export-button"
                        onClick={() => setIsOpenExportModal(true)}
                    >
                        <p>экспорт</p>
                        <WordIcon />
                    </button> */}
                </div>
                <div className="statistic-grid-container">
                    {notEmpty ? (
                        periods.map((el: string, index) => (
                            <React.Fragment key={el}>
                                <StatisticChart
                                    type={el}
                                    selectedYear={selectedYear}
                                    index={index}
                                />
                            </React.Fragment>
                        ))
                    ) : notEmpty !== null && !organizationStatistics.length ? (
                        <StatisticEmpty />
                    ) : (
                        <></>
                    )}

                    {Boolean(organizationStatistics.length) && (
                        <>
                            <div
                                style={{
                                    display: "block",
                                    backgroundColor: "#3b3b3b",
                                    width: "100%",
                                    height: "2px",
                                    borderRadius: "2px",
                                    flexShrink: 0,
                                }}
                            />

                            <div>
                                <h2 className="analytics-title">
                                    Статистика по исполнителям
                                </h2>

                                <div>
                                    <div
                                        className="custom-table"
                                        style={{
                                            gridTemplateColumns:
                                                "max-content 1fr max-content max-content max-content",
                                        }}
                                    >
                                        <div className="custom-table-row">
                                            <div className="custom-table-cell --head">
                                                {"№"}
                                            </div>
                                            <div className="custom-table-cell --head">
                                                {"Наименование исполнителя"}
                                            </div>
                                            <div className="custom-table-cell --head">
                                                {"Запланировано"}
                                            </div>
                                            <div className="custom-table-cell --head">
                                                {"Опубликовано"}
                                            </div>
                                            <div className="custom-table-cell --head">
                                                {"Процент исполнения"}
                                            </div>
                                        </div>

                                        {sortedOrganizationStatistics.map(
                                            (
                                                { name, total, done, procent },
                                                index
                                            ) => {
                                                return (
                                                    <div className="custom-table-row">
                                                        <div className="custom-table-cell">
                                                            {index + 1}
                                                        </div>
                                                        <div className="custom-table-cell">
                                                            {name}
                                                        </div>
                                                        <div className="custom-table-cell --right-x">
                                                            {Number(
                                                                total
                                                            ).toLocaleString(
                                                                "ru-RU"
                                                            )}
                                                        </div>
                                                        <div className="custom-table-cell --right-x">
                                                            {Number(
                                                                done
                                                            ).toLocaleString(
                                                                "ru-RU"
                                                            )}
                                                        </div>
                                                        <div className="custom-table-cell --right-x">
                                                            {procent + "%"}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal isOpen={isOpenExportModal} isSmallModal>
                <div className="export-modal">
                    <div
                        className="export-modal-close-icon"
                        onClick={() => setIsOpenExportModal(false)}
                    >
                        <Cancel />
                    </div>
                    <div className="export-modal-year">
                        <Dropdown
                            options={statisticYears}
                            selectedOpt={year}
                            onSelect={(option) => setYear(option)}
                            label="Выберите год"
                        />
                    </div>
                    <div className="export-modal-month">
                        <Dropdown
                            options={monthsOptions}
                            selectedOpt={month}
                            onSelect={(option) => setMonth(option)}
                            label="Выберите месяц"
                        />
                    </div>
                    <div className="export-modal-button">
                        <Button
                            text="Экспорт"
                            onClick={downloadFile}
                            blueColor
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default StatiscticPage;
