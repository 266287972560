import { useEffect, useRef, useState } from "react";
import { getNotifications, readNotifications } from "../../services";
import { IEvent, INotificationPage } from "../../types";
import {GrayCircle, GreenCircle, RedCircle} from "../Icons";
import { Notification } from './../Icons';
import Pagination from "../UI/Pagination";

const Notifications = () => {
   const [notifications, setNotifications] = useState<IEvent[]>();
   const [amountOfNots, setAmountOfNots] = useState<number>(0);
   const [notifiationPageHeight, setNotificationPageHeight] = useState<number>(0);
   const [isOpen, setIsOpen] = useState<boolean>(false);
   const [notificationHeader, setNotificationHeader] = useState<{active: string, disabled1: string, disabled2: string}>(
      {active: "ПОСЛЕДНЯЯ НЕДЕЛЯ", disabled1: "СЕГОДНЯ", disabled2: "ВСЕ"}
   );
   const [notificationInterval, setNotificationInterval] = useState<{startDate: string, endDate: string}>({
      startDate:  new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
      endDate: (new Date).toISOString().substring(0, 10)
   });
   const [page, setPage] = useState<number>(1);
   const [totalCount, setTotalCount] = useState<number>(0);
   const modalRef = useRef<any>();
   const bellRef = useRef<any>();
   const perPage: number = 7;
   const token = localStorage.getItem("authData") ? JSON.parse(localStorage.getItem("authData") as string).auth_token : null;

   useEffect(() => {
      if (visualViewport) {
         visualViewport.addEventListener('resize', () => {
            calculatePageSize();
         });
      }
      calculatePageSize();
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   useEffect(() => {
      interval();
      setPage(1);
   }, [notificationHeader]);

   useEffect(() => {
      loadNotifications(page);
      countUnreadNotifications();
   }, [notificationInterval]);

   useEffect(() => {
      markAsRead()
   }, [page, isOpen]);

   const loadNotifications = async(page: number) => {
      if (page && perPage && token) {
         const response = await getNotifications(token, notificationInterval.startDate, notificationInterval.endDate, page, perPage,);
         if (!response.isError) {
            const receivedNotifications: INotificationPage = response.data as INotificationPage;
            setNotifications(receivedNotifications.results);
            setTotalCount(receivedNotifications.count);
            setPage(page);
         }
      }
   }

   const countUnreadNotifications = async () => {
      if (token) {
         let unreadCount = 0;
         const response = await getNotifications(token, notificationInterval.startDate, notificationInterval.endDate);
         if (!response.isError) {
            const receivedNotifications: IEvent[] = response.data as IEvent[];
               for (let i = 0; i < receivedNotifications.length; i++) {
                  if (!receivedNotifications[i].have_been_read) {
                     unreadCount++;
                  }
               }
            setAmountOfNots(unreadCount);
         }
      }
  };

   const markAsRead = async() => {
      if (notifications){
         const unreadIds: number[] = notifications.filter((notification: IEvent) => !notification.have_been_read).map((notification: IEvent) => notification.id);
         if (unreadIds.length > 0) {
            await readNotifications(token, unreadIds);
            countUnreadNotifications();
         }
      }
   }

   const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && bellRef.current && !bellRef.current.contains(event.target)) {
         markAsRead();
         setIsOpen(false);
      }
   };

   const calculatePageSize = () => {
      const pageHeight = window.innerHeight;
      const header = document.getElementById("header");
      if (header) {
         const headerHeight = header.offsetHeight;
         setNotificationPageHeight(pageHeight - headerHeight);
      }
   };

   const interval = ():void => {
      if (notificationHeader.active === "ПОСЛЕДНЯЯ НЕДЕЛЯ") {
         const today = new Date();
         const lastWeek = new Date(today);
         lastWeek.setDate(today.getDate() - 7);
         const todayISOString = today.toISOString().substring(0, 10);
         const lastWeekISOString = lastWeek.toISOString().substring(0, 10);
         setNotificationInterval({startDate: lastWeekISOString, endDate: todayISOString});
      }
      if (notificationHeader.active === "СЕГОДНЯ") {
         setNotificationInterval({startDate: new Date().toISOString().substring(0, 10), endDate: new Date().toISOString().substring(0, 10)});
      }
      if (notificationHeader.active === "ВСЕ") {
         setNotificationInterval({startDate: "", endDate: ""});
      }
   }

   const changeActive = (selected: string, key: string) => {
      setNotificationHeader((prevState) => ({...prevState, active: selected, [key]: notificationHeader.active}));
   }

   return (
      <>
         <div className="notification-icon" onClick={() => setIsOpen(!isOpen)} ref={bellRef}>
            <Notification />
            <span className={notifications?.length && amountOfNots !== 0 ? "notification-amount" : ""}>
               {notifications?.length && amountOfNots !== 0 ? amountOfNots : null}
            </span>
         </div>

         {isOpen && (
            <div className="notification-modal" style={{height: notifiationPageHeight}}>
               <div className="notification-modal-window" ref={modalRef}>
                  <div className="notification-modal-window-header">
                     <span className="notification-modal-window-header-active">{notificationHeader.active}</span>
                     <span
                        className="notification-modal-window-header-not-active"
                        onClick={() => changeActive(notificationHeader.disabled1, "disabled1")}
                     >
                        {notificationHeader.disabled1}
                     </span>
                     <span
                        className="notification-modal-window-header-not-active"
                        onClick={() => changeActive(notificationHeader.disabled2, "disabled2")}
                     >
                        {notificationHeader.disabled2}
                     </span>
                  </div>
                  <div className="notification-items">
                     {notifications?.length
                     ? (notifications.map((object: IEvent, index) => (
                        <div key={index} className="notification-item">
                           <div className="notification-item-status">
                              <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                                 Статус события
                                 {object.status === "done" ? <GreenCircle /> : <GrayCircle />}
                              </div>
                              {!object.have_been_read &&
                                 <div><RedCircle /></div>}
                           </div>
                           <div className="notification-item-name">{object.name}</div>
                           <div className="notification-item-date">
                              Дата публикации: {object.date.substring(8, 10)}.{object.date?.substring(5, 7)}.{object.date?.substring(0, 4)}
                           </div>
                        </div>
                     )))
                     : <div className="empty" style={{height: notifiationPageHeight}}>Уведомлений нет</div>}
                  </div>
                  <div style={{position: "sticky", bottom: 0, background: "#fff", paddingBottom: "16px"}}>
                     <Pagination
                        totalCount={totalCount}
                        currentPage={page}
                        perPage={perPage}
                        onChange={(number) =>
                        loadNotifications(number)}
                     />
                  </div>
               </div>
            </div>
         )}
      </>
   )
}

export default Notifications;