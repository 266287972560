import React, {useEffect, useState, useRef} from 'react';
import { IOption } from '../../types';
import { Cancel } from '../Icons';

interface ISelectedTags {
    selected: IOption[];
    sliceCount: number;
    onChange: (selected: IOption[]) => void;
    onClickMore: () => void;
}

const SelectedTags: React.FC<ISelectedTags> = ({selected, sliceCount, onClickMore, onChange}) => {
    const visibleItems = selected.slice(0, sliceCount);

    const handleDeleteTag = (option: IOption) => {
        let updatedList = selected.filter((val) => val.value !== option.value);
        onChange(updatedList);
    }

    return (
        <div className="selected-tags">
            {visibleItems.map((option) => (
                <div key={option.value} className="tag">
                    <div className="tag-content">
                        {option.label}
                        <div className="delete-button-container" onClick={() => handleDeleteTag(option)}>
                            <Cancel bgColor="transparent" color="#505050" style={{ width: "12px", height: "12px"}}/>
                        </div>
                    </div>
                </div>
            ))}
            {selected.length > sliceCount && (
                <div className="more-button" onClick={onClickMore}>
                    еще
                </div>
            )}
        </div>
    );
};

export default SelectedTags;