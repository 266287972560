export function mentionHintString(source: string) {
    const words = source.split(" ");

    const wrappedWords = words.map((word) =>
        word.toLowerCase().startsWith("аким") ||
        word.toLowerCase().startsWith("акім")
            ? `<span class="--hint-mention">${word}</span>`
            : word
    );

    return wrappedWords.join(" ");
}
