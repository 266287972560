import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import Input from "../UI/Input";
import Dropdown from "../UI/Dropdown";
import InputDatePicker from "../UI/InputDate";
import { Delete } from "../Icons";
import {
    getOrganizations,
    getAllPublications,
    createEvents,
    updateEvent,
    getEventTypes,
} from "../../services";
import { format } from "date-fns";
import { IOption, IEvent } from "../../types";

interface IEventForm {
    closeModal: () => void;
    event: IEvent | null;
    onUpdate: () => void;
    selectedDayInCalendar?: Date | undefined;
}

const EventForm: React.FC<IEventForm> = ({
    closeModal,
    event,
    onUpdate,
    selectedDayInCalendar,
}) => {
    const [eventName, setEventName] = useState<{
        value: string;
        error: boolean;
    }>({ value: "", error: false });
    const [responsibleDepartments, setResponsibleDepartments] = useState<{
        value: IOption | null;
        error: boolean;
    }>({ value: null, error: false });
    const [selectedDate, setSelectedDate] = useState<{
        value: Date | undefined;
        error: boolean;
    }>({
        value: selectedDayInCalendar ? selectedDayInCalendar : undefined,
        error: false,
    });
    const [eventType, setEventType] = useState<{
        value: IOption | null;
        error: boolean;
    }>({ value: null, error: false });
    const [publicationFields, setPublicationFields] = useState<
        {
            url: string;
            media_id: string | null;
            error: boolean;
            urlError: boolean;
            id?: number;
            is_deleted?: boolean;
        }[]
    >([{ url: "", media_id: null, error: false, urlError: false }]);
    const [eventTypes, setEventTypes] = useState<IOption[]>([]);
    const [organizationOptions, setOrganizationOptions] = useState<IOption[]>(
        []
    );
    const [publicationOptions, setPublicationOptions] = useState<IOption[]>([]);
    const [urlError, setUrlError] = useState<boolean>(false);

    useEffect(() => {
        fetchOrganizations();
        fetchPublications();
        fetchEventTypes();
    }, []);

    useEffect(() => {
        if (event) {
            setEventName({ value: event.name, error: false });
            setSelectedDate({ value: new Date(event.date), error: false });
            setPublicationFields(
                event.publications.map((pub: any) => ({
                    url: pub.url,
                    id: pub.id,
                    media_id: pub.media_id,
                    error: false,
                    urlError: false,
                }))
            );

            if (organizationOptions.length > 0) {
                const organization = organizationOptions.find(
                    (option) => option.value === event.organization_id
                );
                if (organization) {
                    setResponsibleDepartments((prevState) => ({
                        ...prevState,
                        value: organization,
                    }));
                }
            }
        }
    }, [event, organizationOptions]);

    const fetchOrganizations = async () => {
        const result = await getOrganizations();

        if (!result.isError) {
            const options = result.data.map((org: any) => ({
                value: org.id,
                label: org.name,
            }));
            setOrganizationOptions(options);
        }
    };

    const fetchPublications = async () => {
        const result = await getAllPublications();

        if (!result.isError) {
            const options = result.data.map((pub: any) => ({
                value: pub.id,
                label: pub.name,
            }));
            setPublicationOptions(options);
        }
    };

    const fetchEventTypes = async () => {
        const results = await getEventTypes();

        if (!results.isError) {
            setEventTypes(
                results.data.map((type) => ({
                    value: type.id,
                    label: type.name,
                }))
            );
        }
    };

    const handleSelectOrganization = (option: IOption) => {
        setResponsibleDepartments({ value: option, error: false });
    };

    const handleSelectEventType = (option: IOption) => {
        setEventType({ value: option, error: false });
    };

    const handlePublicationChange = (
        index: number,
        key: string,
        value: string | number | boolean
    ) => {
        const updatedFields = publicationFields.map((field, i) =>
            i === index ? { ...field, [key]: value } : field
        );
        setPublicationFields(updatedFields);
    };

    const handleAddPublicationField = () => {
        setPublicationFields([
            ...publicationFields,
            { url: "", media_id: null, error: false, urlError: false },
        ]);
    };

    const handleDeletePublicationField = (
        id: number | undefined,
        index: number
    ) => {
        if (id) {
            const updatedFields = publicationFields.map((field, i) =>
                i === index ? { ...field, is_deleted: true } : field
            );
            setPublicationFields(updatedFields);
        } else {
            const updatedFields = publicationFields.filter(
                (_, i) => i !== index
            );
            setPublicationFields(updatedFields);
        }
    };

    const isDeleteButton = () => {
        let isDeleteItems = 0;

        publicationFields.forEach((field) => {
            if (field.is_deleted) isDeleteItems++;
        });

        if (isDeleteItems > 0)
            return publicationFields.length - isDeleteItems > 1;
        return publicationFields.length > 1;
    };

    const fieldsValidation = () => {
        if (!eventName.value) {
            setEventName((prevState) => ({
                ...prevState,
                error: true,
            }));
        } else {
            setEventName((prevState) => ({
                ...prevState,
                error: false,
            }));
        }

        if (!responsibleDepartments.value) {
            setResponsibleDepartments((prevState) => ({
                ...prevState,
                error: true,
            }));
        } else {
            setResponsibleDepartments((prevState) => ({
                ...prevState,
                error: false,
            }));
        }

        if (!selectedDate.value) {
            setSelectedDate((prevState) => ({
                ...prevState,
                error: true,
            }));
        } else {
            setSelectedDate((prevState) => ({
                ...prevState,
                error: false,
            }));
        }

        if (!eventType.value) {
            setEventType((prevState) => ({
                ...prevState,
                error: true,
            }));
        } else {
            setEventType((prevState) => ({
                ...prevState,
                error: false,
            }));
        }

        let fieldError = 0;

        publicationFields.forEach((field) => {
            if (!field.media_id) {
                field.error = true;
                fieldError++;
            } else {
                field.error = false;
            }
        });

        return (
            !eventName.value ||
            !responsibleDepartments.value ||
            !selectedDate.value ||
            fieldError > 0
        );
    };

    const handleSave = async () => {
        const validation = fieldsValidation();

        if (validation) {
            return;
        } else {
            const publications = publicationFields.map((field) => ({
                ...(field.id && { id: field.id }),
                media_id: field.media_id,
                url: field.url || "",
                ...(field.is_deleted !== undefined && {
                    is_deleted: field.is_deleted,
                }),
            }));

            const data = {
                name: eventName.value,
                organization_id: Number(responsibleDepartments.value?.value),
                date: selectedDate.value
                    ? format(selectedDate.value, "yyyy-MM-dd")
                    : "",
                publications: publications,
                event_type_id: Number(eventType.value?.value),
            };

            let result;

            if (event) {
                result = await updateEvent(data, event.id);
            } else {
                result = await createEvents(data);
            }

            if (result.isError) {
                handleErrors(result.error);
            } else {
                onUpdate();
                closeModal();
            }
        }
    };

    const handleErrors = (error: any) => {
        if (
            error.publications.length === 1 &&
            error.publications[0].url ===
                "The publications array contains duplicate URLs."
        ) {
            setUrlError(true);
        } else {
            setUrlError(false);
            error.publications.forEach((pub: any, index: number) => {
                if (pub.url) {
                    setPublicationFields((prevState) => {
                        const newState = [...prevState];
                        if (newState[index]) {
                            newState[index] = {
                                ...newState[index],
                                urlError: true,
                            };
                        }
                        return newState;
                    });
                } else {
                    setPublicationFields((prevState) => {
                        const newState = [...prevState];
                        if (newState[index]) {
                            newState[index] = {
                                ...newState[index],
                                urlError: false,
                            };
                        }
                        return newState;
                    });
                }
            });
        }
    };

    return (
        <div className="event-form-wrapper">
            <div>
                <div className="event-form-actions">
                    <Button text="Сохранить" onClick={handleSave} />
                    <Button text="Отменить" onClick={() => closeModal()} />
                </div>
                <div className="event-form-scroll">
                    <div className="event-form">
                        <div className="event-form-title">
                            {event
                                ? "Редактировать мероприятие"
                                : "Создать новое мероприятие"}
                        </div>
                        <Input
                            value={eventName.value}
                            placeholder="Введите название"
                            label="Название мероприятия"
                            onChange={(e) =>
                                setEventName({ value: e, error: false })
                            }
                            error={eventName.error}
                            errorText="*Обязательное поле"
                        />
                        <div className="event-form-two-field">
                            <Dropdown
                                options={organizationOptions}
                                onSelect={handleSelectOrganization}
                                selectedOpt={responsibleDepartments.value}
                                label="Ответственные ведомства"
                                defaultText="Выберите ведомство"
                                error={responsibleDepartments.error}
                                errorText="*Организация не выбрана"
                            />
                            <InputDatePicker
                                value={selectedDate.value}
                                onChange={(date) =>
                                    setSelectedDate({
                                        value: date,
                                        error: false,
                                    })
                                }
                                label="Дата проведения"
                                error={selectedDate.error}
                                errorText="*Дата не выбрана"
                            />
                        </div>
                        <div className="event-form-two-field">
                            <Dropdown
                                options={eventTypes}
                                onSelect={handleSelectEventType}
                                selectedOpt={eventType.value}
                                label="Тип мероприятия"
                                defaultText="Выберите тип мероприятия"
                                error={eventType.error}
                                errorText="*Тип мероприятия не выбрана"
                            />
                        </div>
                    </div>
                    <div className="event-form-publisher-field-container">
                        {urlError && (
                            <div className="url-error">
                                Ссылки на публикации должны быть уникальными
                            </div>
                        )}
                        {publicationFields.map(
                            (field, index) =>
                                !field.is_deleted && (
                                    <div
                                        className="event-form-publisher-field"
                                        key={index}
                                    >
                                        <div className="event-form-publisher-field-wrapper">
                                            <Dropdown
                                                options={publicationOptions}
                                                onSelect={(option) =>
                                                    handlePublicationChange(
                                                        index,
                                                        "media_id",
                                                        option.value
                                                    )
                                                }
                                                selectedOpt={
                                                    publicationOptions.find(
                                                        (opt) =>
                                                            opt.value ===
                                                            field.media_id
                                                    ) || null
                                                }
                                                label="Публикация в СМИ"
                                                defaultText="Выберите источник"
                                                error={field.error}
                                                errorText="*Организация не выбрана"
                                                inputSearch
                                            />
                                            <div className="event-form-publisher-field-trash">
                                                <Input
                                                    value={field.url}
                                                    placeholder="Прикрепите ссылку"
                                                    label="Ссылка"
                                                    onChange={(e) =>
                                                        handlePublicationChange(
                                                            index,
                                                            "url",
                                                            e
                                                        )
                                                    }
                                                    link
                                                    error={field.urlError}
                                                    errorText="Некорректная ссылка"
                                                />
                                                {isDeleteButton() && (
                                                    <div
                                                        onClick={() =>
                                                            handleDeletePublicationField(
                                                                field.id,
                                                                index
                                                            )
                                                        }
                                                        className="event-form-publisher-field-trash-icon"
                                                    >
                                                        <Delete />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
            <div className="event-form-add-btn">
                <Button
                    text="Добавить публикацию"
                    onClick={handleAddPublicationField}
                    big
                    blueColor
                />
            </div>
        </div>
    );
};

export default EventForm;
