import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import AsideMenuItem, { AsideMenuItemProps } from './AsideMenuItem';
import HeaderAsideMenuStyles from './HeaderAsideMenu.module.scss';
import { ArrowLeft, BurgerMenu, Cancel } from '../Icons';
import { isAdmin } from '../../data/role';
import { burgerMenuList } from '../../data/dropdownValues';
import { useLocation, useNavigate } from 'react-router-dom';
import { IOption } from '../../types';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { changePassword } from '../../services';

const IS_DEV = process.env.REACT_APP_ENV === 'development';

const ASIDE_ITEMS_PRODUCTION: AsideMenuItemProps[] = [
    {
        name: 'Главное',
        href: 'https://situation-centre.kz/',
    },
    {
        name: 'Модуль GIS',
        href: 'https://maps.situation-centre.kz/',
    },
    {
        name: 'Медиапланирование',
        href: 'https://media.situation-centre.kz/',
        disabled() {
            return true;
        },
    },
    {
        name: 'Аналитические дайджесты',
        href: 'https://situation-centre.kz/digests/',
    },
];

const ASIDE_ITEMS_DEVELOPMENT: AsideMenuItemProps[] = [
    {
        name: 'Главное',
        href: 'https://sit.robosoft.kz/',
    },
    {
        name: 'Модуль GIS',
        href: 'https://maps.robosoft.kz/',
    },
    {
        name: 'Медиапланирование',
        href: 'https://media.robosoft.kz/',
        disabled() {
            return true;
        },
    },
    {
        name: 'Аналитические дайджесты',
        href: 'https://sit.robosoft.kz/digests/',
    },
];

const ASIDE_ITEMS_BY_ENV = {
    true: ASIDE_ITEMS_DEVELOPMENT,
    false: ASIDE_ITEMS_PRODUCTION,
};

const asideItems = ASIDE_ITEMS_BY_ENV[String(IS_DEV) as keyof typeof ASIDE_ITEMS_BY_ENV]

const HeaderAsideMenu: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showMenu, setShowMenu] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
    const [isLogOut, setIsLogOut] = useState<boolean>(false);
    const [oldPwd, setOldPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
    const [newPwd, setNewPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
    const [checkPwd, setCheckPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
    const [specialError, setSpecialError] = useState<{oldPwdIncorrect: boolean, pwdMismatch: boolean}>({oldPwdIncorrect: false, pwdMismatch: false});

    const handleToogleMenu = () => setShowMenu((prev) => !prev);

    const asideClassNames = useMemo(
        () => {
            return `${HeaderAsideMenuStyles['header-aside-menu']} ${!showMenu ? HeaderAsideMenuStyles['--aside-closed'] : ''}`;
        }, 
        [showMenu],
    );

    const getProjectItems = () => isAdmin() ? burgerMenuList : burgerMenuList.filter(el => el.value !== "users");

    const handleOptionClick = (opt: IOption) => {
        navigate(`/${opt.value}`);
        setShowMenu(false);
    };

    const resetAllStates = () => {
       setOldPwd({value: "", error: false});
       setNewPwd({value: "", error: false});
       setCheckPwd({value: "", error: false});
       setSpecialError({oldPwdIncorrect: false, pwdMismatch: false});
       setIsChangePassword(false);
    }
 
    const validationFields = () => {
       let hasError = false;
       if (!oldPwd.value) {
          setOldPwd((prevState) => ({...prevState, error: true}));
          hasError = true;
       } else {
          setOldPwd((prevState) => ({...prevState, error: false}));
       }
 
       if (!newPwd.value) {
          setNewPwd((prevState) => ({...prevState, error: true}));
          hasError = true;
       } else {
          setNewPwd((prevState) => ({...prevState, error: false}));
       }
 
       if (!checkPwd.value) {
          setCheckPwd((prevState) => ({...prevState, error: true}));
          hasError = true;
       } else {
          setCheckPwd((prevState) => ({...prevState, error: false}));
       }
 
       if (newPwd.value && checkPwd.value && newPwd.value !== checkPwd.value) {
          setSpecialError((prevState) => ({...prevState, pwdMismatch: true}));
          setNewPwd((prevState) => ({...prevState, error: true}));
          setCheckPwd((prevState) => ({...prevState, error: true}));
          hasError = true;
       } else {
          setSpecialError((prevState) => ({...prevState, pwdMismatch: false}));
       }
 
       return hasError;
    }

    const handleSave = async() => {
       const validation = validationFields();
 
       if (validation) {
          return
       } else {
          const response = await changePassword(oldPwd.value, newPwd.value, JSON.parse(localStorage.getItem("authData") as string).auth_token);
          if (!response.isError) {
             resetAllStates();
          } else {
             if (response.isError && response.error === "error400") {
                setSpecialError(prevState => ({ ...prevState, oldPwdIncorrect: true }));
             } else {
                resetAllStates();
             }
          }
       }
    }
 

    useEffect(() => {
        const eventHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setShowMenu(false);
            }
        };

        document.addEventListener('keydown', eventHandler);

        return () => document.removeEventListener('keydown', eventHandler);
    }, []);

    return (
        <>
            <button className={HeaderAsideMenuStyles['header-menu-btn']} type="button" onClick={handleToogleMenu}> 
                <BurgerMenu />
            </button>

            {ReactDOM.createPortal(
                <>
                    <aside className={asideClassNames}>
                        <div className={HeaderAsideMenuStyles['header-aside-menu-inner']}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: "64px", }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", }}>
                                    {asideItems.map((item, index) => {
                                        return <AsideMenuItem {...item} key={index} />;
                                    })}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", }}>
                                    {getProjectItems().map((option: IOption) => (
                                        /* eslint-disable */
                                        <li
                                            key={option.value}
                                            className={HeaderAsideMenuStyles['header-aside-menu-link']}
                                            onClick={() => handleOptionClick(option)}
                                            aria-disabled={location.pathname === `/${option.value}`}
                                        >
                                            {option.label}
                                        </li>
                                    ))}
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", marginTop: "auto", }}>
                                <li className={HeaderAsideMenuStyles['header-aside-menu-link']} onClick={() => setIsChangePassword(true)}>Сменить пароль</li>
                                <li className={HeaderAsideMenuStyles['header-aside-menu-link']} onClick={() => setIsLogOut(true)}>Выйти</li>

                                <button
                                    type="button"
                                    className={HeaderAsideMenuStyles['header-aside-menu-link']}
                                    onClick={handleToogleMenu}
                                >
                                    <ArrowLeft />
                                    Закрыть
                                </button>
                            </div>

                        </div>

                        <div className={HeaderAsideMenuStyles['header-aside-menu-backdrop']} onClick={handleToogleMenu} />
                    </aside>
                    
                    
                    <Modal isOpen={isChangePassword} isSmallModal>
                        <div className="delete-modal">
                            <div
                            className="delete-modal-close-icon"
                            onClick={resetAllStates}
                            >
                            <Cancel color="#7A7A7A" />
                            </div>
                            <div className="delete-modal-content">
                            <p className="delete-modal-content-title">
                                Создайте новый пароль:
                            </p>

                            <Input
                                label="Введите старый пароль"
                                value={oldPwd.value} 
                                placeholder="Пароль" 
                                type="password"
                                onChange={(e) => setOldPwd({value: e, error: false})} 
                                errorText={specialError.oldPwdIncorrect ? "*Старый пароль введен не верно" : (oldPwd.error ? "*Обязательное поле" : "")}
                                error={oldPwd.error || specialError.oldPwdIncorrect}
                            />

                            <Input
                            label="Введите новый пароль"
                                value={newPwd.value} 
                                placeholder="Пароль"
                                type="password"
                                onChange={(e) => setNewPwd({value: e, error: false})} 
                                errorText={specialError.pwdMismatch ? "" : (newPwd.error ? "*Обязательное поле" : "")}
                                error={newPwd.error || specialError.pwdMismatch}
                            />

                            <Input
                            label="Введите новый пароль еще раз"
                                value={checkPwd.value} 
                                placeholder="Пароль"
                                type="password"
                                onChange={(e) => setCheckPwd({value: e, error: false})} 
                                errorText={specialError.pwdMismatch ? "*Пароли не совпадают" : (checkPwd.error ? "*Обязательное поле" : "")}
                                error={checkPwd.error || specialError.pwdMismatch}
                            />
                            </div>
                            <div className="delete-modal-content-btns">
                                <Button
                                    text="Выйти"
                                    onClick={resetAllStates}
                                    big
                                />
                                <Button text="Сохранить" onClick={handleSave} blueColor big />
                            </div>
                        </div>
                    </Modal>

                    <Modal isOpen={isLogOut} isSmallModal>
                        <div className="delete-modal">
                            <div
                            className="delete-modal-close-icon"
                            onClick={() => setIsLogOut(false)}
                            >
                            <Cancel color="#7A7A7A" />
                            </div>
                            <div className="delete-modal-content">
                            <p className="delete-modal-content-title">
                                Вы уверены, что хотите выйти из аккаунта?
                            </p>
                            </div>
                            <div className="delete-modal-content-btns">
                            <Button
                                text="НЕТ"
                                onClick={() => setIsLogOut(false)}
                                big
                            />
                            <Button text="ДА" onClick={() => {localStorage.removeItem("authData"); setIsLogOut(false); window.location.reload()}} blueColor big />
                            </div>
                        </div>
                    </Modal>
                </>
                ,
                document.getElementById('aside-menu')!,
            )}
        </>
    );
};

export default HeaderAsideMenu;
