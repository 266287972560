import React, {useEffect, useState} from 'react';
import {Eye} from "../Icons";

interface CheckboxProps {
    label: string;
    isChecked?: boolean
    url?: string | null
    isDisabled?: boolean
    onChange?: (status: string) => void;
    labelStyle?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({label, isChecked, url, onChange, labelStyle, isDisabled}) => {
    const [checked, setChecked] = useState<boolean>(!!isChecked);

    useEffect(() => {
        setChecked(!!isChecked);
    }, [isChecked]);

    const handleChange = () => {
        if (onChange) {
            onChange(!checked? "done" : "not_done");
        }
        setChecked(!checked);
    };

    const getUrl = () => {
        if (url) {
            const isExternal = /^(http|https):\/\//.test(url);
            if (isExternal) {
                return url;
            } else {
                return `https://${url}`
            }
        }
    };

    return (
        <label className={`checkbox ${checked ? 'checked' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                disabled={isDisabled}
            />
            <span className="checkbox-checkmark"></span>
            {url ?
                <a href={getUrl()} className="checkbox-label checkbox-link" target="_blank" rel="noopener noreferrer">{label}</a>
                :
                <span className="checkbox-label" style={labelStyle? labelStyle: {}}>{label}</span>
            }
            {checked && (
                <>
                    {/* <Eye />
                    <span className="checkbox-view">759.000</span> */}
                </>
            )}
        </label>
    );
};

export default Checkbox;
