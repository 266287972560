import { IDistricts, IPublications } from "../../types";
import { Cancel, Delete, Edit } from "../Icons";
import React, { useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { deleteMedia } from "../../services";
import MediaForm from "./MediaForm";
import { isAdmin, isEditor } from "../../data/role";

interface IPublicationsTable {
    publications: IPublications[];
    districts: IDistricts[];
    loadPublications: () => void;
    currentPage: number;
    itemsPerPage: number
}

const MediaTable: React.FC<IPublicationsTable> = ({
    publications,
    districts,
    loadPublications,
    currentPage,
    itemsPerPage
}) => {
    const [editButtonClicked, setEditButtonClicked] = useState<boolean>(false);
    const [selectedPublication, setSelectedPublication] = useState<IPublications>();
    const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);

    const handleDelete = async (currentPublication: IPublications) => {
        const response = await deleteMedia(currentPublication.id);
        if (!response.isError) {
            loadPublications();
        }
    };

    const districtDecoder = (districtId: number): string => {
        const target: IDistricts = districts.find((object) => object.id === districtId) as IDistricts;
        if (target) {
            return target.name;
        }
        return "";
    };

    return (
        <>
            <table className="table">
                <thead className="table-header">
                    <tr>
                        <th>№</th>
                        <th>НАИМЕНОВАНИЕ</th>
                        <th>КАТЕГОРИЯ</th>
                        <th>РАЙОН</th>
                        {(isAdmin() || isEditor()) &&
                            <th>ДЕЙСТВИЕ</th>
                        }
                    </tr>
                </thead>

                <tbody>
                    {publications.map((item: IPublications, index: number) =>
                        <tr className={index % 2 ? "dark-row" : ""} key={item.id}>
                            <td className="cell">{(currentPage -1) * itemsPerPage + index + 1}</td>
                            <td className="cell cell-media-name" >{item.name}</td>
                            <td className="cell">{item.category_name}</td>
                            <td className="cell">{districtDecoder(item.district_id as number)}</td>
                            <td className="cell">
                                <div className="media-action">
                                    {(isAdmin() || isEditor()) &&
                                        <span
                                            className="media-action-button"
                                            onClick={() => {
                                                setEditButtonClicked(true);
                                                setSelectedPublication(item);
                                            }}
                                        >
                                            <Edit />
                                        </span>
                                    }
                                    { isAdmin() &&
                                        <span
                                            className="media-action-button"
                                            onClick={() => {
                                                setDeleteButtonClicked(true);
                                                setSelectedPublication(item);
                                            }}
                                        >
                                            <Delete />
                                        </span>
                                    }
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Modal isSmallModal isOpen={editButtonClicked}>
                <MediaForm
                    closeModal={() => setEditButtonClicked(false)}
                    onSubmit={loadPublications}
                    publicationForEdit={selectedPublication}
                    districts={districts}
                    districtDecoder={districtDecoder}
                />
            </Modal>

            <Modal isOpen={deleteButtonClicked} isSmallModal>
                <div className="delete-modal">
                    <div className="delete-modal-close-icon" onClick={() => setDeleteButtonClicked(false)}>
                        <Cancel color="#7A7A7A" />
                    </div>
                    <div className="delete-modal-content">
                        <p className="delete-modal-content-title">Вы уверены что хотите <br/> удалить публикацию:</p>
                        <p className="delete-modal-content-subtitle">
                            {selectedPublication?.name}({selectedPublication?.category_name})
                        </p>
                    </div>
                    <div className="delete-modal-content-btns">
                        <Button
                            text="Удалить"
                            onClick={() => {
                                setDeleteButtonClicked(false);
                                handleDelete(selectedPublication as IPublications);
                            }}
                            redColor
                            big
                        />
                        <Button text="Отмена" onClick={() => setDeleteButtonClicked(false)} big />
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default MediaTable;